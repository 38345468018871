import React, { useEffect, useState } from "react";

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Dashboard() {
    const [search, setSearch] = useState([]);
    //    setSearch('Loading..');

    const [inputs, setInputs] = useState({});

    const localKey = checkCookie();

    // prepare link for customer view
    var url = window.location.href;
    url = url.replace("dashboard", "view?customer=");

    /**
     * The function takes the value of the input field and filters the table rows based on the value of
     * the input field
     * @param event - The event object is a JavaScript event that is sent to an element when an event
     * occurs.
     */
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);

        var input, filter, table, tr, td, i, txtValue;

        input = document.getElementById("input");
        filter = input.value.toUpperCase();
        table = document.getElementById("customers");
        tr = table.getElementsByTagName("tr");

        var val = input.value.trim();

        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };
    // fetch data from api
    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        //headers
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // token is invalid, clear local storage to remove
                    // localStorage.clear();
                    // DEBUG: console.log("invalid");
                    // navigate back to login
                    setSearch("An Error occured when trying to fetch customers");
                } else {
                    // load data from server
                    setSearch(Object.entries(valRes[1]));
                    // DEBUG: console.log(Object.entries(valRes[1]));

                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    // console.log("fetch");
                }
            })
            .catch((error) => {
                // clear local storage
                // log this action
                //  localStorage.clear();
                console.log("error", error);
            });
    }

    return (
        <main className="flex w-full flex-col text-center items-center bg-white ml-[300px]">
            <h1 className="text-8xl my-20">Dashboard</h1>
            <br />
            <br />
            <input
                type="text"
                id="input"
                className="w-[50%]        
                            form-control
                            block
                            px-4
                            py-3
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            mt-10
                            focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                name="search"
                placeholder="Kunde suchen.."
                value={inputs.search}
                onChange={handleChange}
                autoFocus
            />
            <br />
            <table className="w-[50%] mb-10" id="customers">
                <tr className="text-center">
                    <th className="text-lg font-bold text-gray-900 py-4">Resultat</th>
                </tr>
                {search.map((ele) => (
                    <tr className="border-b text-sm text-gray-900">
                        <td className="py-4">
                            <a href={url + ele[1][1]}>{ele[1][1]}</a>
                        </td>
                    </tr>
                ))}
            </table>

            <br />
            <br />
        </main>
    );
}

export default Dashboard;

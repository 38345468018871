import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// icon
import { RiAdminLine } from "react-icons/ri";

/**
 * It takes a cookie name as a parameter, and returns the value of the cookie with that name
 * @param cname - The name of the cookie you want to get.
 * @returns The value of the cookie.
 */
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
/**
 * If the cookie exists, return the value of the cookie, otherwise return false
 * @returns The value of the cookie named "key"
 */
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function UserView() {
    // get jwt from cookie
    const localKey = checkCookie();

    // navigate from react router
    const navigate = useNavigate();

    // crm user data
    const [userId, setUserId] = useState([]);
    const [user, setUser] = useState([]);

    // loading
    const [loading, setLoading] = useState(true);

    // errors
    const [error, setError] = useState([]);
    const [updateError, setUpdateError] = useState([]);

    // content from input fields
    const [inputs, setInputs] = useState({});

    // api response for patchin crm user data request
    const [insert, setInsert] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);
    };

    // on new Interaction form submit
    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        // errors variable
        var errors = "";

        // check if values are valid
        var id = user[0][1];
        var username = "";
        var email = "";
        var oldPassword = "";
        var password = "";
        var rpassword = "";

        // cannot use inputs.<name> here because inputs don't get updated when a field has no change
        username = document.getElementById("username").value;
        email = document.getElementById("email").value;
        oldPassword = document.getElementById("oldPassword").value;
        password = document.getElementById("password").value;
        rpassword = document.getElementById("rpassword").value;

        if (username === "") {
            errors += "Username darf nicht leer sein. ";
        }
        if (email === "") {
            errors += "E-Mail darf nicht leer sein. ";
        }
        if(oldPassword === "") {
            errors += "Altes Passwort darf nicht leer sein. "
        }
        if (password === "" || rpassword === "") {
            errors += "Passwort darf nicht leer sein. ";
        } else if (password !== rpassword) {
            errors += "Passwörter müssen übereinstimmen!";
        }

        // check if there are any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to patch the user

            // patch request
            var patchHeader = new Headers();
            patchHeader.append("Authorization", "Bearer " + localKey);

            // body
            var raw = JSON.stringify({
                id: id,
                username: username,
                email: email,
                oldPassword: oldPassword,
                password: password,
            });

            // request options
            var requestOptions = {
                method: "PATCH",
                headers: patchHeader,
                body: raw,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=patchCRMUser",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:
                    // console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        //console.log(valRes[0]);
                    } else {
                        // load data from server
                        //   console.log(Object.entries(valRes[1]));
                        //    console.log(valRes[1]);
                        // DEBUG: console.log(Object.entries(valRes[1]));
                        // DEBUG:   console.log(valRes[1]);

                        // refresh userdata
                        fetchData();
                    }
                    setInsertStatus(valRes[0]);
                    setInsert(valRes[1]);
                })
                .catch((error) => {
                    // log this action
                    console.log("ERROR: ", error);
                    errors += error;
                    setUpdateError(errors);
                });
                // reset form
                inputs.oldPassword = "";
                inputs.password = "";
                inputs.rpassword = "";
        }
    };

    // on first render
    useEffect(() => {
        let id = "";

        // try catch is used because I am too lazy to check if the parameter actually exists
        // if there is no parameter in the url it will automatically land in catch instead of showing an error
        try {
            // check for parameters in url
            // this is not very secure because we don't check for a specific parameter
            // (I don't have time to fix this)
            let urlString = window.location.href;
            let paramString = urlString.split("?")[1]; // get all parameters from url after ?
            let param_arr = paramString.split("&"); // split paramters and store them in an array
            for (let i = 0; i < param_arr.length; i++) {
                // we only need one parameter (customer name) but this allows us to extend the number of parameters (currently only gets first parameter and value FIXLATER)
                let pair = param_arr[i].split("="); // split parameter and value

                // DEBUG:
                // console.log("param:" + pair[0]);
                // console.log("val:" + pair[1]);
                id = pair[1];
                setUserId(id);
            }
        } catch (err) {
            // no parameters where found
            // DEBUG: console.log(err);
            // navigate back to dashboard
            navigate("/CRM/dashboard");
            return false;
        }
    }, []);

    useEffect(() => {
        //
        if (user.length > 1) {
            document.getElementById("username").value = user[1][1];
            document.getElementById("email").value = user[2][1];
        }
    }, [user]);

    // when userid is set
    useEffect(() => {
        if (typeof userId === "string") {
            // ????, somehow works
            fetchData();
        }
    }, [userId]);

    // fetch crm user data
    function fetchData() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getCRMUser&id=" +
                userId,
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                // DEBUG: console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // store customer information
                    // DEBUG: console.log(valRes[1]);

                    // check if valRes[1] is null (query was successful but no user was found)
                    if (valRes[1] === null) {
                        //     navigate("/CRM/dashboard");
                        console.log("null");
                    } else {
                        setUser(Object.entries(valRes[1])); // valRes[1] = response array without status code // valRes[1][0] // customer
                    }

                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    //   console.log("Customer:"+customer);

                    setLoading(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("ERROR:", error);
                //console.log("error");
            });
    }

    return (
        <div className="flex w-full flex-col text-center ml-[300px]">
            <h1 className="text-6xl my-10">Useransicht</h1>
            <div className="w-full flex flex-col items-center">
                <div className="flex flex-row">
                    <h2 className="text-3xl ml-1 mb-5 flex items-center">
                        {" "}
                        <RiAdminLine size={30} className="mr-3" />{" "}
                        {loading ? "Loading.." : user[1][1]}
                    </h2>
                </div>
                <div id="wrapper" className="flex flex-row flex-wrap m-5">
                    {loading ? (
                        "Loading.."
                    ) : (
                        <div className="flex flex-wrap">
                            <p>
                                <b className="ml-5">ID: </b>
                                {user[0][1]}
                            </p>
                            <p>
                                <b className="ml-5">Username: </b>
                                {user[1][1]}
                            </p>
                            <p>
                                <b className="ml-5">E-Mail: </b>
                                <a href={"mailto:" + user[2][1]}>{user[2][1]}</a>
                            </p>
                        </div>
                    )}
                </div>
                <hr className="h-2 text-black font-bold w-[90%]" />
            </div>
            <div className="w-full flex flex-row mt-8 ml-10">
                <div className="w-full flex flex-col align-middle items-center">
                    <h2 className="text-3xl">Bearbeiten</h2>
                    <form
                        className="flex w-1/2 flex-col items-center mt-5"
                        onSubmit={handleSubmit}
                    >
                        <h2 className="text-xl">Bearbeiten</h2>
                        <div className="flex w-3/4 justify-center mt-5">
                            <input
                                type="text"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Username.."
                                name="username"
                                id="username"
                                value={inputs.username}
                                onChange={handleChange}
                            />
                            <input
                                type="email"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="E-Mail.."
                                name="email"
                                id="email"
                                value={inputs.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex w-3/4 justify-center mt-3">
                        <input
                                type="password"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Altes Passwort.."
                                name="oldPassword"
                                id="oldPassword"
                                value={inputs.oldPassword}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex w-3/4 justify-center mt-3">
                            <input
                                type="password"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Neues Passwort.."
                                name="password"
                                id="password"
                                value={inputs.password}
                                onChange={handleChange}
                            />
                            <input
                                type="password"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Passwort wiederholen.."
                                name="rpassword"
                                id="rpassword"
                                value={inputs.rpassword}
                                onChange={handleChange}
                            />
                        </div>
                        <div
                            id="error"
                            className="flex w-full justify-center mt-1 text-red-500"
                        >
                            {error}
                        </div>
                        <div className="flex w-3/4 justify-start mt-3">
                            <input
                                type="submit"
                                value="Bearbeiten"
                                name="submit"
                                className="inline-block m-2 w-1/3 px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                            />
                        </div>
                        <div
                            id="success"
                            className={
                                insertStatus == 200
                                    ? "flex w-full justify-center mt-3 text-green-500"
                                    : "flex w-full justify-center mt-3 text-red-500"
                            }
                        >
                            {insert}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserView;

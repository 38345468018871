import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import {BsFileEarmark} from 'react-icons/bs'
import {BsTrash} from 'react-icons/bs';

/**
 * It takes a cookie name as a parameter, and returns the value of the cookie with that name
 * @param cname - The name of the cookie you want to get.
 * @returns The value of the cookie.
 */
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
/**
 * If the cookie exists, return the value of the cookie, otherwise return false
 * @returns The value of the cookie named "key"
 */
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Interaktionen() {
    // navigate from react router
    const navigate = useNavigate();

    // input field values
    const [inputs, setInputs] = useState([]);

    // errors
    const [error, setError] = useState([]);
    const [updateError, setUpdateError] = useState(false);

    // status that is returned when an interaction is updated
    const [updateStatus, setUpdateStatus] = useState([]);


    // interaction data
    const [interaction, setInteraction] = useState([]);
    const [interactionID, setInteractionID]=useState([]);

    // new files
    const [file, setFile]=useState([]);
    const [multipleFiles, setMultipleFiles]=useState(false); // user wants to upload multiple files

    // current crm user data
    const [user, setUser] = useState([]);

    // selectable users
    const [users, setUsers] = useState([]);

    // delete
    const [deleteStatus, setDeleteStatus]=useState([]);
    const [deleteMsg, setDeleteMsg]=useState([]);

    // loading states
    const [loadingInteraction, setLoadingInteraction] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);

    // get jwt from cookie
    const localKey = checkCookie();

    // on input field change
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);
    };

    // on new Interaction form submit
    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        // errors variable
        var errors = "";

        // input vairables
        var id = interaction[0][1];
        var type = document.getElementById("type").value;
        var titel = document.getElementById("titel").value;
        var inhalt = document.getElementById("inhalt").value;
        var notizen = document.getElementById("notizen").value;
        var dauer = document.getElementById("dauer").value;
        var datum = document.getElementById("datum").value;
        var prioritaet = document.getElementById("prioritaet").value;
        var fk_empfaenger = document.getElementById("empfaenger").value;
        
        var files = [];
        if(multipleFiles) {
            files = file;
        } else {
            files.push(document.getElementById("fileUrl").value); // convert to array
        }


        // NEEDS IMPROVEMENT! (\n and <br> are displayed in plain if used in the error message)
        // check if required inputs are not empty
        if (fk_empfaenger === "" || fk_empfaenger === "-") {
            errors += "Empfäger darf nicht leer sein. ";
        } else if (type === "") {
            errors += "Type darf nicht leer sein. ";
        } else if (titel === "") {
            errors += "Titel darf nicht leer sein. ";
        } else if (inhalt === "") {
            errors += "Inhalt darf nicht leer sein ";
        } else if (datum === "") {
            errors += "Datum darf nicht leer sein. ";
        } else if (notizen === "") {
            notizen = "-"; // in backend notizen is required but they are actually not important
            // I am too lazy to rewrite it
        }

        // check if there where any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to update interaktion values

            // patch request
            var patchHeader = new Headers();
            patchHeader.append("Authorization", "Bearer " + localKey);

            // body
            var raw = JSON.stringify({
                id:id,
                fk_empfaenger:fk_empfaenger,
                type:type,
                titel:titel,
                inhalt:inhalt,
                notizen:notizen,
                dauer:dauer,
                datum:datum,
                prioritaet:prioritaet,
                files: files
            });

            // request options
            var requestOptions = {
                method: "PATCH",
                headers: patchHeader,
                body: raw,
                redirect: "manual",
            };

            // update customer
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=patchCustomerInteraction",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:
                    // console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        // DEBUG:  console.log(valRes[0]);
                        setUpdateError(valRes[1]);
                    } else {
                        // load data from server
                        //   console.log(Object.entries(valRes[1]));
                        //    console.log(valRes[1]);
                        // DEBUG: console.log(Object.entries(valRes[1]));
                        // DEBUG:   console.log(valRes[1]);

                        // refresh customer information
                        fetchData();

                        // set status message
                        setUpdateError(valRes[1]);
                    }
                    // set the api response status
                    setUpdateStatus(valRes[0]);
                })
                .catch((error) => {
                    // log this action
                    console.log("ERROR: ", error);
                    errors += error;
                    setUpdateError(errors);
                });

                // reset form

                // reset files
                setMultipleFiles(false);
                setFile("");
        }
    };

    function addFile(){
        var fileUrl = "";
        // get new file url
        if(inputs.fileUrl) {
            fileUrl = inputs.fileUrl.trim();
        }

        // add to files array
        if(fileUrl !== "") {
            setFile(file.concat(fileUrl));
            setMultipleFiles(true);
            inputs.fileUrl = "";
        }
    }

    function deleteFile(id) {
        if(id !== undefined) {
            // API Request to delete a file
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localKey);

            var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };


                fetch(
                    "https://crm.digitalnatives.ch/CRM/API/api.php?action=deleteCustomerFile&id=" +
                        id,
                    requestOptions
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        let arrayRes = Object.entries(result);
                        let valRes = arrayRes.map((ele) => ele[1]);
                        //DEBUG:  console.log(valRes);
                        if (valRes[0] !== 200) {
                            // DEBUG: console.log("invalid");
                            // error message here
                            setDeleteStatus(valRes[0]);
                            setDeleteMsg(valRes[1]);
                        } else {

                            // DEBUG: console.log(Object.entries(valRes[1]));
                            // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                            
                            // refresh data
                            fetchData();
                        }
                    })
                    .catch((error) => {
                        // log this action
                        console.log("error", error);
                    });
        }
    }


    const handleDeleteInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        // check if input is DELETE
        if(document.getElementById("delete").value === "DELETE") {
            // enable the delete button
            document.getElementById("del").disabled = false;
        } else {
            // disable the delete button
            document.getElementById("del").disabled = true;
        }
      // DEBUG:  console.log(document.getElementById("delete").value);
    }

    const handleDelete = (event) => {
        event.preventDefault();
        // check again if DELETE was typed
        if(inputs.delete === "DELETE") {
            // API Request to delete the interaction
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localKey);
    
            var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };
    

                fetch(
                    "https://crm.digitalnatives.ch/CRM/API/api.php?action=deleteCustomerInteraction&id=" +
                        interactionID,
                    requestOptions
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        let arrayRes = Object.entries(result);
                        let valRes = arrayRes.map((ele) => ele[1]);
                        //DEBUG:  console.log(valRes);
                        if (valRes[0] !== 200) {
                            // DEBUG: console.log("invalid");
                            // error message here
                            setDeleteStatus(valRes[0]);
                            setDeleteMsg(valRes[1]);
                        } else {

                            // DEBUG: console.log(Object.entries(valRes[1]));
                            // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                            navigate('/CRM/dashboard');
                        }
                    })
                    .catch((error) => {
                        // log this action
                        console.log("error", error);
                    });

        } else {
            // disable the delete button
            document.getElementById("del").disabled = true;
        }
    }

    // this useEffect only executes once on first time loading
    useEffect(() => {
        fetchData();
    }, []);



    // fetch interaction data and and all crm customers
    function fetchData() {
        let interactionId = "";

        // try catch is used because I am too lazy to check if the parameter actually exists
        // if there is no parameter in the url it will automatically land in catch instead of showing an error
        try {
            // check for parameters in url
            // this is not very secure because we don't check for a specific parameter
            // (I don't have time to fix this)
            let urlString = window.location.href;
            let paramString = urlString.split("?")[1]; // get all parameters from url after ?
            let param_arr = paramString.split("&"); // split paramters and store them in an array
            for (let i = 0; i < param_arr.length; i++) {
                // we only need one parameter (customer name) but this allows us to extend the number of parameters (currently only gets first parameter and value FIXLATER)
                let pair = param_arr[i].split("="); // split parameter and value

                // DEBUG:
                // console.log("param:" + pair[0]);
                // console.log("val:" + pair[1]);
                interactionId = pair[1];
            }
        } catch (err) {
            // no parameters where found
            // DEBUG: console.log(err);
            // navigate back to dashboard
            navigate("/CRM/dashboard");
            return false;
        }

        setInteractionID(interactionId);

        // make api requste to fetch information about this interaction
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getCustomerInteraction&interactionId=" +
                interactionId,
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    // error message here
                } else {
                    // load data from server

                    // check if response is not null
                    if (valRes[1] === null) {
                        setInteraction("empty");
                        console.log(valRes);
                    } else {
                        setInteraction(Object.entries(valRes[1]));
                    }
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    setLoadingInteraction(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });

        // fetch all CRM Users (for select options)
        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCRMUsers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    // error message here
                } else {
                    // load data from server

                    // check if response is not null
                    if (valRes[1] === null) {
                        setUsers("empty");
                       // DEBUG: console.log(valRes);
                    } else {
                        setUsers(Object.entries(valRes[1]));
                    }
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG: console.log(valRes[1]); 
                    setLoadingUsers(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });
    }

    // fetch CRM User(empfaenger) and Customer
    useEffect(() => {
        // check if interaction was loaded
        if (interaction.length > 0) {
            // fetch crm user and customer
            // make api requste to fetch information about this interaction
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localKey);

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=getCRMUser&id=" +
                    interaction[8][1],
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        // error message here
                    } else {
                        // load data from server

                        // check if response is not null
                        if (valRes[1] === null) {
                            setUser("empty");
                         // DEBUG:   console.log(valRes);
                        } else {
                            setUser(Object.entries(valRes[1]));
                        }
                        // DEBUG: console.log(Object.entries(valRes[1]));
                        // DEBUG: console.log(valRes[1]);
                        setLoadingUser(false);
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });
        }
    }, [interaction]);


    // when the empfaenger (user) from the interaction is loaded
    useEffect(()=>{
        // insert the interaction values into the bearbeiten input fields
        if(user.length > 1 && !loadingUsers) {
            document.getElementById("empfaenger").value = user[0][1];
            document.getElementById("type").value = interaction[1][1];
            document.getElementById("titel").value = interaction[2][1];
            document.getElementById("inhalt").value = interaction[3][1];
            document.getElementById("notizen").value = interaction[4][1];
            if(interaction[5][1] !== null) {
                document.getElementById("dauer").value = interaction[5][1];
            } else {
                document.getElementById("dauer").value = 0;
            }
            document.getElementById("datum").value = interaction[6][1];
            if(interaction[7][1] !== null) {
                document.getElementById("prioritaet").value = interaction[7][1];
            }

            // files
            // DEBUG: console.log(Object.entries(interaction[10][1]));
        }
    },[user, users])

    return (
        <div className="flex w-full flex-col text-center ml-[300px]">
            <h1 className="text-6xl mt-10">
                Interaktion NR. {loadingInteraction ? "Loading.." : interaction[0][1]}
            </h1>
            <div className="flex w-full flex-col items-center">
                <div className="flex flex-col m-5 w-5/6">
                    <span className="text-xl mt-5">Interaktion bearbeiten</span>
                    <form
                        className="flex w-full flex-col mb-10"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-3 flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="empfaenger" className="mb-1 font-bold">
                                    Empfänger / Verantwortlicher<sup className="text-red-500">*</sup>
                                </label>
                                <select
                                    className="form-select text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="empfaenger"
                                    name="empfaenger"
                                    value={inputs.empfaenger}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Empfänger --
                                    </option>
                                    {loadingUsers ? (
                                        <option value="-">Loading..</option>
                                    ) : (
                                        users.map((ele) => (
                                            // DEBUG: console.log("ID: " + users[0][0] + ", Name: " + users[0][1][0])
                                            <option id={ele[0][0]} value={ele[0][0]}>
                                                {ele[1][0]}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="type" className="mb-1 font-bold">
                                    Type<sup className="text-red-500">*</sup>
                                </label>
                                <select
                                    className="form-select text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="type"
                                    name="type"
                                    value={inputs.type}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Type --
                                    </option>
                                    <option value="E-Mail">E-Mail</option>
                                    <option value="Meeting">Meeting</option>
                                    <option value="Meeting Online">Meeting Online</option>
                                    <option value="Telefon">Telefon</option>
                                    <option value="Brief">Brief</option>
                                    <option value="Gespräch">Gespräch</option>
                                    <option value="Sonstiges">Sonstiges</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex w-full mt-2">
                            <div className="flex flex-col w-full text-left m-2">
                                    <label for="titel" className="mb-1 font-bold">
                                        Titel<sup className="text-red-500">*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                        placeholder="Titel"
                                        name="titel"
                                        id="titel"
                                        value={inputs.titel}
                                        onChange={handleChange}
                                    />
                            </div>
                        </div>
                        <div className="flex w-full justify-center mt-2">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="empfaenger" className="mb-1 font-bold">
                                    Inhalt<sup className="text-red-500">*</sup>
                                </label>
                                <textarea
                                    className="form-control block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Inhalt"
                                    name="Inhalt"
                                    id="inhalt"
                                    value={inputs.inhalt}
                                    rows="10"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="notizen" className="mb-1 font-bold">
                                    Notizen
                                </label>
                                <textarea
                                    className="form-control block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="notizen"
                                    name="Notizen"
                                    id="notizen"
                                    value={inputs.notizen}
                                    rows="10"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center mt-2">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="dauer" className="mb-1 font-bold">
                                    Dauer
                                </label>
                                <input
                                    type="number"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Dauer (Minuten)"
                                    name="dauer"
                                    id="dauer"
                                    min="0"
                                    value={inputs.dauer}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="datum" className="mb-1 font-bold">
                                    Datum<sup className="text-red-500">*</sup>
                                </label>
                                <input
                                    type="date"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    name="datum"
                                    id="datum"
                                    value={inputs.datum}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="prioritaet" className="mb-1 font-bold">
                                    Priorität
                                </label>
                                <select
                                    className="form-select text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="prioritaet"
                                    name="prioritaet"
                                    value={inputs.prioritaet}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Priorität --
                                    </option>
                                    <option value="Hoch">Hoch</option>
                                    <option value="Mittel">Mittel</option>
                                    <option value="Tief">Tief</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex w-full justify-center mt-2 text-left">
                            <div className="w-full m-2">
                                <p className="font-bold">Dateien</p>
                                <div className="flex">
                                    { 
                                        loadingInteraction ? (
                                            "Loading.."
                                        ) : (
                                            interaction[10][1] !== null ? (
                                                Object.entries(interaction[10][1]).map((ele)=>(
                                                    <div className="mr-2 mt-2 p-2 flex rounded border-2 align-middle items-center border-gray-500 shadow-md hover:border-[#e4752b] hover:shadow-lg focus:border-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
                                                        <a className="flex"
                                                        href={ele[1][0]} target="_blank">
                                                        <BsFileEarmark size={23} /> <p className="ml-2 font-thin text-sm text-gray-600 mr-2">{ele[1][1]}</p>
                                                        </a>
                                                        <button type="button" onClick={() => deleteFile(ele[0])} className="text-red-500 font-bold hover:shadow-sm"><BsTrash size={20} /></button>
                                                    </div>
                                                ))
                                            ) : (
                                                "Diese Interaktion enthält keine Datei"
                                            )

                                        )
                                   }
                                </div>
                                <div className="flex flex-col w-full justify-center mt-3">
                                    {
                                        multipleFiles ? (
                                            <div className="flex flex-col">
                                            <b className="flex font-bold">Neue Dateien: <p className="ml-2 font-normal text-sm text-gray-500">(Nicht gespeichert)</p></b>
                                            {file.map((ele)=>(
                                                <a href={ele} target="_blank" className="underline">{ele}</a>
                                            ))}
                                            </div>
                                        ) : (
                                                ""
                                        )
                                    }
                                </div>
                                <div className="flex flex-col w-full justify-center mt-3">
                                    <p className="font-bold">Neue Datei</p>
                                    <div className="flex flex-row">
                                        <input
                                            type="url"
                                            className="form-control m-2 ml-0 block w-1/2 rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                            placeholder="File URL"
                                            name="fileUrl"
                                            id="fileUrl"
                                            value={inputs.fileUrl}
                                            onChange={handleChange}
                                        />
                                        <button type="button" onClick={() => addFile()} className="form-control m-2 ml-0 block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out hover:border-[#f78538] focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none">+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="error"
                            className="flex w-full justify-center mt-3 text-red-500"
                        >
                            {error}
                        </div>
                        <div className="flex w-full justify-center mt-3">
                            <input
                                type="submit"
                                value="Speichern"
                                name="submit"
                                class="inline-block m-2 w-1/2 px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                            />
                        </div>
                        <div
                            id="success"
                            className={
                                updateStatus == 200
                                    ? "flex w-full justify-center mt-3 text-green-500"
                                    : "flex w-full justify-center mt-3 text-red-500"
                            }
                        >
                            {updateError}
                        </div>
                    </form>
                    <form className="my-36 w-full flex flex-col justify-center items-center" onSubmit={handleDelete}>
                        <h1 className="font-bold text-4xl mb-10 text-red-500">Danger Zone!</h1>
                        <label for="delete" className="font-bold">Diese Interaktion löschen</label>
                        <input
                        type="text"
                        id="delete"
                        className="w-1/4        
                                form-control
                                block
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-2
                                focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                        name="delete"
                        placeholder={"Type: DELETE"}
                        value={inputs.delete}
                        onChange={handleDeleteInput}
                        />
                        <button disabled id="del" type="submit" class="inline-block w-1/4 px-6 m-2 py-2.5 bg-red-600 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out disabled:bg-red-400 disabled:text-gray-100 focus:border-[#f78538]">Diese Interaktion Löschen</button>
                        <div
                                id="success"
                                className={
                                    deleteStatus !== 200
                                        ? "flex w-full justify-center mt-3 text-red-500"
                                        : "flex w-full justify-center mt-3 text-green-500" // impossible to reach (navigates before this is displayed)
                                }
                            >
                                {deleteMsg}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Interaktionen;

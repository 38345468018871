import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

// icons
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";

// byte size convert (enable for fileupload)
// this library converts bytes into kb, mb, gb..
// import byteSize from "byte-size";

/**
 * It takes a cookie name as a parameter, and returns the value of the cookie with that name
 * @param cname - The name of the cookie you want to get.
 * @returns The value of the cookie.
 */
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
/**
 * If the cookie exists, return the value of the cookie, otherwise return false
 * @returns The value of the cookie named "key"
 */
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function View() {
    // navigate from react router
    const navigate = useNavigate();

    // customer data
    const [customer, setCustomer] = useState([]);
    const [interactions, setInteractions] = useState([]);
    const [customerName, setCustomerName] = useState([]); // in order to call the fetchCustomer function (requires customername) when refreshing

    // crm users
    const [users, setUsers] = useState([]);

    // api response for addCustomer request
    const [insert, setInsert] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    // inputfield values
    const [inputs, setInputs] = useState([]); // {loadingInfo ? "loading.." : customer[3][1]}

    // fileupload (the not implemented version where you actually upload a file)
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileSize, setFileSize] = useState();

    // fileupload (the version where you upload the file url)
    const [file, setFile]=useState([]);
    const [multipleFiles, setMultipleFiles]=useState(false); // user wants to upload multiple files

    // loading states (true means that the data is still loading)
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loadingInteractions, setLoadingInteractions] = useState(true);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);

    // errors when new interaction or file is added
    const [error, setError] = useState([]);
    const [updateError, setUpdateError] = useState([]);

    // update
    const [updateStatus, setUpdateStatus]=useState([]);

    // delete
    const [deleteStatus, setDeleteStatus]=useState([]);
    const [deleteMsg, setDeleteMsg]=useState([]);

    // customer icon
    const [icon, setIcon] = useState([]);

    // get jwt from cookie
    const localKey = checkCookie();

    // prepare link for interaktionen view
    var url = window.location.href;
    url = url.split("?")[0];
    url = url.replace("view", "interaktionen?i=");

/**
 * It filters the table rows and attributes for the inputfield value
 * @param event - The event object is a JavaScript event that is sent to an element when an event
 * occurs.
 */
// on input field change
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);

        // filter all table rows and attributes for the inputfield value
        var input, filter, table, tr, i;

        input = document.getElementById("input");
        filter = input.value.toUpperCase();
        table = document.getElementById("interactions");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            var type = tr[i].getElementsByTagName("td")[1];
            var titel = tr[i].getElementsByTagName("td")[2];
            var inhalt = tr[i].getElementsByTagName("td")[3];
            var datum = tr[i].getElementsByTagName("td")[4];
            if (type && titel && inhalt && datum) {
                type = type.textContent || type.innerText;
                titel = titel.textContent || titel.innerText;
                inhalt = inhalt.textContent || inhalt.innerText;
                datum = datum.textContent || datum.innerText;
                if (
                    titel.toUpperCase().indexOf(filter) > -1 ||
                    type.toUpperCase().indexOf(filter) > -1 ||
                    inhalt.toUpperCase().indexOf(filter) > -1 ||
                    datum.toUpperCase().indexOf(filter) > -1
                ) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };


    // fileupload (currently disabled, won't be in the 2022 summer version)

    /*
	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
        
        // set filesize
        // DEBUG: console.log(byteSize(event.target.files[0].size));
        // DEBUG: console.log(event.target.files[0]);
        setFileSize(byteSize(event.target.files[0].size));
	};
*/

    
/**
 * It takes the form data, checks if it's valid and then sends it to the server
 * @param event - The event that triggered the function.
 */
// on new Interaction form submit
    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError(""); 

        // errors variable
        var errors = "";

        // input vairables
        var type = "";
        var titel = "";
        var inhalt = "";
        var notizen = "";
        var dauer = "";
        var datum = "";
        //var file = "";
        var fileUrl = "";
        var prioritaet = "";
        var fk_empfaenger = "";
        var fk_customer = customer[0][1];

        // DEBUG: console.log(inputs);

        // check if variable has value and assign
        if (inputs.empfaenger) {
            fk_empfaenger = inputs.empfaenger.trim();
        }
        if (inputs.type) {
            type = inputs.type.trim();
        }
        if (inputs.titel) {
            titel = inputs.titel.trim();
        }
        if (inputs.inhalt) {
            inhalt = inputs.inhalt.trim();
        }
        if (inputs.notizen) {
            notizen = inputs.notizen.trim();
        }
        if (inputs.dauer) {
            dauer = inputs.dauer.trim();
        }
        if (inputs.datum) {
            datum = inputs.datum.trim();
        }
        if (inputs.prioritaet) {
            prioritaet = inputs.prioritaet.trim();
        }
        if (!fk_customer) {
            errors += "Could not load customer ID! ";
        }
        if(inputs.fileUrl) {
            fileUrl = inputs.fileUrl.trim();
        }
        /*
        if(!selectedFile || selectedFile === "") {
            file = null;
            console.log("invalid file or empty");
        } else {
            file = selectedFile;
            console.log(file);
        }
        */

        // NEEDS IMPROVEMENT! (\n and <br> are displayed in plain)
        // check if required inputs are not empty
        if (fk_empfaenger === "" || fk_empfaenger === "-") {
            errors += "Empfäger darf nicht leer sein. ";
        } else if (type === "") {
            errors += "Type darf nicht leer sein. ";
        } else if (titel === "") {
            errors += "Titel darf nicht leer sein. ";
        } else if (inhalt === "") {
            errors += "Inhalt darf nicht leer sein ";
        } else if (datum === "") {
            errors += "Datum darf nicht leer sein. ";
        } else if (notizen === "") {
            notizen = "-"; // in backend notizen is required but they are actually not important
            // I am too lazy to rewrite it
        }

        // check if there where any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to insert a new interaction

            // ###############################################################################################################################
            // IMPORTANT: This part was originally written to upload a file so the code looks different. 
            //            The Upload works, just remove the content-type application/json and uncomment the other content type
            //            Then remove the request body, uncomment the formdata and add formdata to the request options instead of request body
            // ###############################################################################################################################
            // header
            var requestHeader = new Headers();
          //  requestHeader.append("Content-Type", "multipart/form-data");
            requestHeader.append("Content-Type", "application/json")
            requestHeader.append("Authorization", "Bearer " + localKey);


            // form data
            /*
            const formData = new FormData();

            formData.append('type', type);
            formData.append('titel', titel);
            formData.append('inhalt', inhalt);
            formData.append('notizen', notizen);
            formData.append('dauer', dauer);
            formData.append('datum', datum);
            formData.append('prioritaet', prioritaet);
            formData.append('fk_empfaenger', fk_empfaenger);
            formData.append('fk_customer', fk_customer);
            formData.append('file', file);
*/


            var files = [];
            if(multipleFiles) {
                files = file;
            } else {
                files.push(document.getElementById("fileUrl").value); // convert to array
            }

            var requestBody = JSON.stringify({
                type: type,
                titel: titel,
                inhalt: inhalt,
                notizen: notizen,
                dauer: dauer,
                datum: datum,
                prioritaet: prioritaet,
                fk_empfaenger: fk_empfaenger,
                fk_customer: fk_customer,
                fileUrl: files
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,             // if you want to uploade a file with formData replace the value with formData
                redirect: "manual",
            };

            // insert new interaction
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=addCustomerInteraction",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setInsert("An Error occured: " + valRes[1]);

                        setInsertStatus(valRes[0]);
                    } else {
                        // load data from server
                        setInsert(valRes[1]);
                        setInsertStatus(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]);

                        // reset form here

                        // reload interactions list
                        fetchData();
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("ERROR: ", error);
                });


                // clear input
                inputs.empfaenger="-";
                inputs.type="-";
                inputs.titel="";
                inputs.inhalt="";
                inputs.notizen="";
                inputs.dauer="";
                inputs.datum="";
                inputs.prioritaet="";
                inputs.fileUrl="";
                setFile("");
                setMultipleFiles(false);
        }
    };


    function addFile(){
        var fileUrl = "";
        // get new file url
        if(inputs.fileUrl) {
            fileUrl = inputs.fileUrl.trim();
        }

        // add to files array
        if(fileUrl !== "") {
            setFile(file.concat(fileUrl));
            setMultipleFiles(true);
            inputs.fileUrl = "";
        }
    }

    const handleDeleteInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        // check if input is DELETE
        if(document.getElementById("delete").value === "DELETE") {
            // enable the delete button
            document.getElementById("del").disabled = false;
        } else {
            // disable the delete button
            document.getElementById("del").disabled = true;
        }
      // DEBUG:  console.log(document.getElementById("delete").value);
    }

    const handleDelete = (event) => {
        event.preventDefault();
        // check again if DELETE was typed
        if(inputs.delete === "DELETE") {
            // API Request to delete the customer
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localKey);
    
            var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };
    

                fetch(
                    "https://crm.digitalnatives.ch/CRM/API/api.php?action=deleteCustomer&id=" +
                        customer[0][1],
                    requestOptions
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        let arrayRes = Object.entries(result);
                        let valRes = arrayRes.map((ele) => ele[1]);
                        //DEBUG:  console.log(valRes);
                        if (valRes[0] !== 200) {
                            // DEBUG: console.log("invalid");
                            // error message here
                            setDeleteStatus(valRes[0]);
                            setDeleteMsg(valRes[1]);
                        } else {

                            // DEBUG: console.log(Object.entries(valRes[1]));
                            // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                            navigate('/CRM/dashboard');
                        }
                    })
                    .catch((error) => {
                        // log this action
                        console.log("error", error);
                    });

        } else {
            // disable the delete button
            document.getElementById("del").disabled = true;
        }
    }

/**
 * It takes the values from the form and sends them to the server
 * @param event - the event that triggered the function
 */
    const handleUpdateSubmit = (event) => {
        event.preventDefault();

        // this somehow does not work because the default values are not actually set for some reason
        // const name = event.target.name;
        // const value = event.target.value;
        // setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        // errors variable
        var errors = "";

        // get values
        var id = customer[0][1];
        var type = document.getElementById("type").value;
        var name = document.getElementById("customername").value;
        var adresse = document.getElementById("adresse").value;
        var plz = document.getElementById("plz").value;
        var ort = document.getElementById("ort").value;
        var telefon = document.getElementById("telefon").value;
        var email = document.getElementById("email").value;
        var website = document.getElementById("website").value;

        //DEBUG: console.log(type + " " + name + " " + adresse + " " + plz + " " + ort + " " + telefon + " " + email + " " + website);

        // patch request
        var patchHeader = new Headers();
        patchHeader.append("Authorization", "Bearer " + localKey);

        // body
        var raw = JSON.stringify({
            id: id,
            type: type,
            name: name,
            adresse: adresse,
            plz: plz,
            ort: ort,
            telefon: telefon,
            email: email,
            website: website,
        });

        // request options
        var requestOptions = {
            method: "PATCH",
            headers: patchHeader,
            body: raw,
            redirect: "manual",
        };

        // update customer
        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=patchCustomer",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:
                // console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    // DEBUG:  console.log(valRes[0]);
                    
                } else {
                    // load data from server
                    //   console.log(Object.entries(valRes[1]));
                    //    console.log(valRes[1]);
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]);

                    // refresh customer information
                    // set customername to patched name so the app does not navigate back to the dashboard because it tries to fetch a customer with an old/not updated name
                    setCustomerName(name);


                    fetchCustomer(name);
                }
                setUpdateStatus(valRes[0]);
                setUpdateError(valRes[1]);
            })
            .catch((error) => {
                // log this action
                console.log("ERROR: ", error);
                errors += error;
                setUpdateError(errors);
            });
    };

/* The above code is using the useEffect hook to fetch the customer data from the API. */
    useEffect(() => {
        if (typeof customerName === "string") {
            fetchCustomer(customerName);
        }
    }, [customerName]);

/**
 * It fetches the customer interactions from the server and sets the state of the component
 */
    // fetch customer interactions
    function fetchData() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomerInteractions&name=" +
                customer[2][1],
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    // error message here
                } else {
                    // load data from server

                    // check if response is not null
                    if (valRes[1] === null) {
                        setInteractions("empty");
                        // console.log(valRes);
                    } else {
                        setInteractions(Object.entries(valRes[1]));
                    }
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    setLoadingInteractions(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });
    }

/**
 * It fetches the customer information from the database and stores it in the state
 * @param customerName - The name of the customer you want to fetch.
 */
    // fetch customer information
    function fetchCustomer(customerName) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getCustomer&customerName=" +
                customerName,
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                // DEBUG: console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // store customer information
                    // DEBUG: console.log(valRes[1]);

                    // check if valRes[1] is null (query was successful but no user was found)
                    if (valRes[1] === null) {
                        navigate("/CRM/dashboard");
                        // DEBUG:  console.log("user was not found");
                    } else {
                        setCustomer(Object.entries(valRes[1])); // valRes[1] = response array without status code // valRes[1][0] // customer
                    }

                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    //   console.log("Customer:"+customer);

                    setLoadingInfo(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("ERROR:", error);
                //console.log("error");
            });
    }


/* Fetching all users from the database and storing them in the state variable users. */
    // this useEffect only executes once on first time loading
    useEffect(() => {
        let customerName = "";

        // try catch is used because I am too lazy to check if the parameter actually exists
        // if there is no parameter in the url it will automatically land in catch instead of showing an error
        try {
            // check for parameters in url
            // this is not very secure because we don't check for a specific parameter
            // (I don't have time to fix this)
            let urlString = window.location.href;
            let paramString = urlString.split("?")[1]; // get all parameters from url after ?
            let param_arr = paramString.split("&"); // split paramters and store them in an array
            for (let i = 0; i < param_arr.length; i++) {
                // we only need one parameter (customer name) but this allows us to extend the number of parameters (currently only gets first parameter and value FIXLATER)
                let pair = param_arr[i].split("="); // split parameter and value

                // DEBUG:
                // console.log("param:" + pair[0]);
                // console.log("val:" + pair[1]);
                customerName = pair[1];
                setCustomerName(customerName);
            }
        } catch (err) {
            // no parameters where found
            // DEBUG: console.log(err);
            // navigate back to dashboard
            navigate("/CRM/dashboard");
            return false;
        }

        // fetch customer iformation
        fetchCustomer(customerName);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        // fetch crm users (needed for fk_empfaenger variable when adding a new interaction)
        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCRMUsers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                // DEBUG: console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // store customer information
                    // DEBUG: console.log(valRes[1]);

                    // check if valRes[1] is null (query was successful but no user was found)
                    if (valRes[1] === null) {
                        //  navigate("/CRM/dashboard");
                    } else {
                        setUsers(Object.entries(valRes[1])); // valRes[1] = response array without status code // valRes[1][0] // customer
                    }

                    // DEBUG: console.log(Object.entries(valRes[1]));

                    setLoadingUsers(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("ERROR:", error);
                //console.log("error");
            });
    }, []);

/* Checking if the customer array has any data in it. If it does, it checks if the customer is a
company or a person. If it is a company, it sets the icon to a building, if it is a person, it sets
the icon to a person. */
    useEffect(() => {
        // check if any customers where loaded
        if (customer.length > 0) {
            // DEBUG: console.log(customer[9][1]);
            if (customer[1][1] == "Firma") {
                setIcon(<HiOutlineOfficeBuilding size={34} />);
            } else {
                setIcon(<AiOutlineUser size={34} />);
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        // to only execute the fetch request when the info about the user is not loaded makes absolutely no sense but somehow it is the only way it works?????????
        // could not find bug or explanation (If you have time please find a solution that makes sense because I don't know how long this will work)
        if (!loadingInfo) {
            // fetch all customer interactions
            // console.log(customer);
            // console.log(loadingInfo);
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomerInteractions&name=" +
                    customer[2][1],
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        // error message here
                    } else {
                        // load data from server

                        // check if response is not null
                        if (valRes[1] === null) {
                            setInteractions("empty");
                            // DEBUG: console.log(valRes);
                        } else {
                            setInteractions(Object.entries(valRes[1]).reverse());
                        }
                        // DEBUG: console.log(Object.entries(valRes[1]));
                        // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                        setLoadingInteractions(false);
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });
        }
    }, [loadingInfo]);

/* The above code is a useEffect that gets executed when customer data is loaded. If customer was
loaded, the default values are set. */
    // useEffect that gets executed when customer data is loaded
    useEffect(() => {
        // if customer was loaded
        if (customer.length > 1) {
            // set default values
            document.getElementById("type").value = customer[1][1];
            document.getElementById("customername").value = customer[2][1];
            document.getElementById("adresse").value = customer[3][1];
            document.getElementById("plz").value = customer[4][1];
            document.getElementById("ort").value = customer[5][1];
            document.getElementById("telefon").value = customer[6][1];
            document.getElementById("email").value = customer[7][1];
            document.getElementById("website").value = customer[9][1];
        }
    }, [customer]);

    return (
        <div className="flex w-full flex-col text-center ml-[300px]">
            <h1 className="text-6xl my-5">Kundenansicht</h1>
            <div className="w-full flex flex-col items-center">
                <div className="flex flex-row">
                    {icon}
                    <h2 className="text-3xl ml-1 mb-2">
                        {" "}
                        {loadingInfo ? "Loading.." : customer[2][1]}
                    </h2>
                </div>
                <hr className="h-2 text-black font-bold w-[90%]" />
            </div>
            <div className="w-full flex flex-row mt-8 ml-10">
                <div className="w-[50%] flex flex-col align-middle">
                    <h2 className="text-3xl">Informationen</h2>
                    <span className="text-xl mt-3">Infos bearbeiten</span>
                    <form onSubmit={handleUpdateSubmit} className="flex flex-col">
                        <div className="mt-5 flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="type" className="mb-1 font-bold">
                                    Type<sup className="text-red-500">*</sup>
                                </label>
                                <select
                                    className="form-select block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="type"
                                    name="type"
                                    value={inputs.type}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Typ --
                                    </option>
                                    <option value="Firma">Firma</option>
                                    <option value="Einzelperson">Einzelperson</option>
                                    <option value="Undefined">Undefined</option>
                                </select>
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="type" className="mb-1 font-bold">
                                    Name<sup className="text-red-500">*</sup>
                                </label>
                                <input
                                    type="text"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    id="customername"
                                    placeholder="Name"
                                    name="customerName"
                                    value={inputs.customerName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="type" className="mb-1 font-bold">
                                    Adresse<sup className="text-red-500">*</sup>
                                </label>
                                <input
                                    type="text"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Adresse"
                                    name="adresse"
                                    id="adresse"
                                    value={inputs.adresse}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="type" className="mb-1 font-bold">
                                    PLZ<sup className="text-red-500">*</sup>
                                </label>
                                <input
                                    type="number"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="PLZ"
                                    min="1000"
                                    max="9999"
                                    name="plz"
                                    id="plz"
                                    value={inputs.plz}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="ort" className="mb-1 font-bold">
                                    Ort<sup className="text-red-500">*</sup>
                                </label>
                                <input
                                    type="text"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Ort"
                                    name="ort"
                                    id="ort"
                                    value={inputs.ort}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="telefon" className="mb-1 font-bold">
                                    Telefon
                                </label>
                                <input
                                    type="tel"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Telefon"
                                    name="telefon"
                                    id="telefon"
                                    value={inputs.telefon}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="email" className="mb-1 font-bold">
                                <a href={loadingInfo ? ("#") : ("mailto:" + customer[7][1])} target="_blank">E-Mail</a>
                                </label>
                                <input
                                    type="email"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="E-Mail"
                                    name="email"
                                    id="email"
                                    value={inputs.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div className="flex flex-col w-full text-left m-2">
                                <label for="website" className="mb-1 font-bold">
                                    <a href={loadingInfo ? ("#") : (customer[9][1])} target="_blank">Website</a>
                                </label>
                                <input
                                    type="url"
                                    className="form-control block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    id="website"
                                    placeholder="Website"
                                    name="website"
                                    value={inputs.website}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-center mt-2">
                            <input
                                type="submit"
                                value="speichern"
                                name="submit"
                                class="inline-block m-2 w-full px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                            />
                        </div>
                        <div
                            id="success"
                            className={
                                updateStatus == 200
                                    ? "flex w-full justify-center mt-3 text-green-500"
                                    : "flex w-full justify-center mt-3 text-red-500"
                            }
                        >
                            {updateError}
                        </div>
                    </form>
                </div>
                <div className="w-[50%] flex flex-col mr-16">
                    <h2 className="text-3xl">Interaktionen</h2>
                    <span className="text-xl mt-5">Neue Interaktion</span>
                    <form
                        className="flex w-full flex-col items-center mb-10"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-10 flex w-full justify-center">
                            <select
                                className="form-select m-2 mr-1 text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                aria-label="Default select example"
                                id="empfaenger"
                                name="empfaenger"
                                value={inputs.empfaenger}
                                onChange={handleChange}
                            >
                                <option selected value="-">
                                    -- Empfänger --
                                </option>
                                {loadingUsers ? (
                                    <option value="-">Loading..</option>
                                ) : (
                                    users.map((ele) => (
                                        // DEBUG: console.log("ID: " + users[0][0] + ", Name: " + users[0][1][0])
                                        <option value={ele[0][0]}>{ele[1][0]}</option>
                                    ))
                                )}
                            </select>
                            <p className="text-red-500">*</p>

                            <select
                                className="form-select m-2 mr-1 text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                aria-label="Default select example"
                                id="type"
                                name="type"
                                value={inputs.type}
                                onChange={handleChange}
                            >
                                <option selected value="-">
                                    -- Type --
                                </option>
                                <option value="E-Mail">E-Mail</option>
                                <option value="Meeting">Meeting</option>
                                <option value="Meeting Online">Meeting Online</option>
                                <option value="Telefon">Telefon</option>
                                <option value="Brief">Brief</option>
                                <option value="Gespräch">Gespräch</option>
                                <option value="Sonstiges">Sonstiges</option>
                            </select>
                            <p className="text-red-500">*</p>
                        </div>
                        <div className="flex w-full justify-center mt-7">
                            <input
                                type="text"
                                className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Titel"
                                name="titel"
                                id="titel"
                                value={inputs.titel}
                                onChange={handleChange}
                            />
                            <p className="text-red-500">*</p>
                        </div>
                        <div className="flex w-full justify-center mt-7">
                            <textarea
                                className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Inhalt"
                                name="inhalt"
                                id="inhalt"
                                value={inputs.inhalt}
                                rows="4"
                                onChange={handleChange}
                            />
                            <p className="text-red-500">*</p>
                        </div>
                        <div className="flex w-full justify-center mt-1">
                            <textarea
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Notizen"
                                name="notizen"
                                id="notizen"
                                value={inputs.notizen}
                                rows="3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex w-full justify-center mt-3">
                            <input
                                type="number"
                                className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                placeholder="Dauer (Minuten)"
                                name="dauer"
                                id="dauer"
                                min="0"
                                value={inputs.dauer}
                                onChange={handleChange}
                            />
                            <input
                                type="date"
                                className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                name="datum"
                                id="datum"
                                value={inputs.datum}
                                onChange={handleChange}
                            />
                            <p className="text-red-500">*</p>
                            <select
                                className="form-select m-2 text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                aria-label="Default select example"
                                id="prioritaet"
                                name="prioritaet"
                                value={inputs.prioritaet}
                                onChange={handleChange}
                            >
                                <option selected value="-">
                                    -- Priorität --
                                </option>
                                <option value="Hoch">Hoch</option>
                                <option value="Mittel">Mittel</option>
                                <option value="Tief">Tief</option>
                            </select>
                        </div>
                        <div className="flex flex-col w-full justify-start mt-3 text-left ">
                            <label for="file" className="ml-3 font-light text-gray-500 italic">
                                Fileupload funktioniert momentan nicht, File URL benutzen.
                            </label>
                            <input
                                class="m-2 block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-500 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none file:border-none file:bg-white file:outline-none"
                                type="file"
                                name="file"
                                disabled
                               // onChange={changeHandler}
                            />
                            {/* When a file is selected, get size and unit with byte-size and display it here */}
                            {isFilePicked ? (
                                <p>Grösse: {fileSize.value} {fileSize.unit}</p>
                            ) : (
                                <p></p>
                                ) }
                        </div>
                        <div className="flex flex-col w-full justify-center mt-3">
                                    {
                                        multipleFiles ? (
                                            <div className="flex flex-col m-2 text-left">
                                            <b className="flex font-bold">Neue Dateien: <p className="ml-2 font-normal text-sm text-gray-500">(Nicht gespeichert)</p></b>
                                            {file.map((ele)=>(
                                                <a href={ele} target="_blank" className="underline">{ele}</a>
                                            ))}
                                            </div>
                                        ) : (
                                                ""
                                        )
                                    }
                                </div>
                                <div className="flex flex-col w-full justify-center mt-3 ml-5">
                                    <p className="font-bold">Neue Datei</p>
                                    <div className="flex flex-row w-full">
                                        <input
                                            type="url"
                                            className="form-control m-2 ml-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                            placeholder="File URL"
                                            name="fileUrl"
                                            id="fileUrl"
                                            value={inputs.fileUrl}
                                            onChange={handleChange}
                                        />
                                        <button type="button" onClick={() => addFile()} className="form-control m-2 ml-0 mr-5 block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none">+</button>
                                    </div>
                                </div>
                        <div
                            id="error"
                            className="flex w-full justify-center mt-3 text-red-500"
                        >
                            {error}
                        </div>
                        <div className="flex w-full justify-center mt-3">
                            <input
                                type="submit"
                                value="Erstellen"
                                name="submit"
                                class="inline-block m-2 w-full px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                            />
                        </div>
                        <div
                            id="success"
                            className={
                                insertStatus == 200
                                    ? "flex w-full justify-center mt-3 text-green-500"
                                    : "flex w-full justify-center mt-3 text-red-500"
                            }
                        >
                            {insert}
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
                <input
                    type="text"
                    id="input"
                    className="w-[80%]        
                            form-control
                            block
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-2
                            focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                    name="search"
                    placeholder="Interaktionen Filtern"
                    value={inputs.search}
                    onChange={handleChange}
                />
                <br />
                <p className="my-2">Total: {interactions.length}</p>
                <br />
                <table id="interactions" className="w-[80%] mb-10 overflow-x-hidden">
                    <tr className="text-center">
                        <th></th>
                        <th className="text-lg font-bold text-gray-900 py-4 px-5 text-ellipsis">
                            Type
                        </th>
                        <th className="text-lg font-bold text-gray-900 py-4 px-5 text-ellipsis">
                            Titel
                        </th>
                        <th className="text-lg font-bold text-gray-900 py-4 px-5 text-ellipsis">
                            Inhalt
                        </th>
                        <th className="text-lg font-bold text-gray-900 py-4 px-5 text-ellipsis">
                            Datum
                        </th>
                    </tr>
                    {interactions === "empty"
                        ? "No interactions found for this customer"
                        : interactions.map((ele) => (
                              <tr className="border-b text-sm text-gray-900">
                                  <td className="max-w-[100px] py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                      <a href={url + ele[0]}><BiLinkExternal size={20} /></a>
                                  </td>
                                  <td className="max-w-[100px] py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                      {ele[1][0]}
                                  </td>
                                  <td className="max-w-[100px] py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                      {ele[1][1]}
                                  </td>
                                  <td className="max-w-[100px] py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                      {ele[1][2]}
                                  </td>
                                  <td className="max-w-[100px] py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                      {ele[1][5]}
                                  </td>
                              </tr>
                          ))}
                </table>
                <form className="my-36 w-1/2 flex flex-col justify-center items-center" onSubmit={handleDelete}>
                    <h1 className="font-bold text-4xl mb-10 text-red-500">Danger Zone!</h1>
                    <label for="delete" className="font-bold">Diesen Kunden löschen</label>
                    <input
                    type="text"
                    id="delete"
                    className="w-1/3        
                            form-control
                            block
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-2
                            focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                    name="delete"
                    placeholder={"Type: DELETE"}
                    value={inputs.delete}
                    onChange={handleDeleteInput}
                />
                    <button disabled id="del" type="submit" class="inline-block w-1/3 px-6 m-2 py-2.5 bg-red-600 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out disabled:bg-red-400 disabled:text-gray-100 focus:border-[#f78538]">{loadingInfo ? "Loading.." : customer[2][1]} löschen?</button>
                    <div
                            id="success"
                            className={
                                deleteStatus !== 200
                                    ? "flex w-full justify-center mt-3 text-red-500"
                                    : "flex w-full justify-center mt-3 text-green-500" // impossible to reach (navigates before this is displayed)
                            }
                        >
                            {deleteMsg}
                        </div>
                </form>
            </div>
        </div>
    );
}

export default View;

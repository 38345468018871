import React, { useEffect, useState } from "react";

// icons
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Kunden() {
    // api respons for getAllCustomer request
    const [search, setSearch] = useState([]);

    // api response for addCustomer request
    const [insert, setInsert] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    // errors when new customer is created
    const [error, setError] = useState([]);

    // content from input fields
    const [inputs, setInputs] = useState({});

    // get jwt from cookie
    const localKey = checkCookie();

    // prepare link for customer view
    var url = window.location.href;
    url = url.replace("kunden", "view?customer=");

    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        // errors variable
        var errors = "";

        // input vairables

        // default values
        var type = "";
        var customerName = "";
        var adresse = "";
        var plz = "";
        var ort = "";
        var telefon = "";
        var email = "";
        var website = "";

        // check if variable has a value
        if (inputs.type) {
            type = inputs.type.trim();
        }
        if (inputs.customerName) {
            customerName = inputs.customerName.trim();
        }
        if (inputs.adresse) {
            adresse = inputs.adresse.trim();
        }
        if (inputs.plz) {
            plz = inputs.plz.trim();
        }
        if (inputs.ort) {
            ort = inputs.ort.trim();
        }
        if (inputs.telefon) {
            telefon = inputs.telefon.trim();
        }
        if (inputs.email) {
            email = inputs.email.trim();
        }
        if (inputs.website) {
            website = inputs.website.trim();
        }

        // DEBUG: console.log(type + " " + customerName + " " + adresse + " " + plz + " " + ort + " " + telefon + " " + email + " " + website);

        // NEEDS IMPROVEMENT! (\n and <br> are displayed in plain)
        // check if required inputs are not empty
        if (type === "" || type === "-") {
            errors += "Kunden Typ darf nicht leer sein. ";
        }
        if (customerName === "") {
            errors += "Kunden Name darf nicht leer sein. ";
        }
        if (adresse === "") {
            errors += "Adresse darf nicht leer sein. ";
        }
        if (plz === "") {
            errors += "Postleitzahl darf nicht leer sein. ";
        }
        if (ort === "") {
            errors += "Ort darf nicht leer sein. ";
        }

        // DEBUG console.log(error);

        // check if there are any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to insert a new user

            // header
            var requestHeader = new Headers();
            requestHeader.append("Content-Type", "text/plain");
            requestHeader.append("Authorization", "Bearer " + localKey);

            // body
            var requestBody = JSON.stringify({
                type: type,
                name: customerName,
                adresse: adresse,
                plz: plz,
                ort: ort,
                telefon: telefon,
                email: email,
                website: website,
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=addCustomer",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setInsert("An Error occured: " + valRes[1]);

                        setInsertStatus(valRes[0]);
                    } else {
                        setInsert(valRes[1]);
                        setInsertStatus(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]);

                        // reset form here
                        // does not work: document.getElementById("customerName").value="";

                        // reload customer list
                        fetchData();
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });

                // clear input fields
                inputs.type = "-";
                inputs.customerName="";
                inputs.adresse="";
                inputs.plz="";
                inputs.ort="";
                inputs.telefon="";
                inputs.email="";
                inputs.website="";
        }
    };

    /**
     * The function takes the value of the input field and filters the table rows based on the value of
     * the input field
     * @param event - The event object is a JavaScript event that is sent to an element when an event
     * occurs.
     */
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);

        var input, filter, table, tr, td, i, txtValue;

        input = document.getElementById("input");
        filter = input.value.toUpperCase();
        table = document.getElementById("customers");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };
    // fetch data from api
    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        //headers
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    setSearch("An Error occured when trying to fetch customers");
                } else {
                    // load data from server
                    setSearch(Object.entries(valRes[1]).reverse());
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });
    }

    return (
        <div className="flex w-full flex-col text-center ml-[350px]">
            <h1 className="text-6xl mt-10">Kunden</h1>
            <div className="mt-10 flex w-full">
                <form
                    className="flex w-1/2 flex-col items-center ml-5"
                    onSubmit={handleSubmit}
                >
                    <h2 className="text-3xl self-start ml-2">Neuer Kunde</h2>
                    <div className="mt-5 flex w-full justify-center">
                        <select
                            className="form-select m-2 mr-1 block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            aria-label="Default select example"
                            id="type"
                            name="type"
                            value={inputs.type}
                            onChange={handleChange}
                        >
                            <option selected value="-">
                                -- Typ --
                            </option>
                            <option value="Firma">Firma</option>
                            <option value="Einzelperson">Einzelperson</option>
                        </select>
                        <p className="text-red-500 ml-0">*</p>
                        <input
                            type="text"
                            className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            id="customername"
                            placeholder="Name"
                            name="customerName"
                            value={inputs.customerName}
                            onChange={handleChange}
                        />
                        <p className="text-red-500 ml-0">*</p>
                    </div>
                    <div className="flex w-full justify-center mt-3">
                        <input
                            type="text"
                            className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            placeholder="Adresse"
                            name="adresse"
                            id="adresse"
                            value={inputs.adresse}
                            onChange={handleChange}
                        />
                        <p className="text-red-500 ml-0">*</p>
                    </div>
                    <div className="flex w-full justify-center mt-3">
                        <input
                            type="number"
                            className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            placeholder="PLZ"
                            min="1000"
                            max="9999"
                            name="plz"
                            id="plz"
                            value={inputs.plz}
                            onChange={handleChange}
                        />
                        <p className="text-red-500 ml-0">*</p>
                        <input
                            type="text"
                            className="form-control m-2 mr-1 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            placeholder="Ort"
                            name="ort"
                            id="ort"
                            value={inputs.ort}
                            onChange={handleChange}
                        />
                        <p className="text-red-500 ml-0">*</p>
                    </div>
                    <div className="flex w-full justify-center mt-3">
                        <input
                            type="tel"
                            className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            placeholder="Telefon"
                            name="telefon"
                            id="tel"
                            value={inputs.telefon}
                            onChange={handleChange}
                        />
                        <input
                            type="email"
                            className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            placeholder="E-Mail"
                            name="email"
                            id="email"
                            value={inputs.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex w-full justify-center mt-3">
                        <input
                            type="url"
                            className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={inputs.website}
                            onChange={handleChange}
                        />
                    </div>
                    <div
                        id="error"
                        className="flex w-full justify-center mt-3 text-red-500"
                    >
                        {error}
                    </div>
                    <div className="flex w-full justify-center mt-3">
                        <input
                            type="submit"
                            value="Erstellen"
                            name="submit"
                            class="inline-block m-2 w-full px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                        />
                    </div>
                    <div
                        id="success"
                        className={
                            insertStatus == 200
                                ? "flex w-full justify-center mt-3 text-green-500"
                                : "flex w-full justify-center mt-3 text-red-500"
                        }
                    >
                        {insert}
                    </div>

                    <div id="infos">
                        <b>Kunden Total: {search.length}</b>
                    </div>
                </form>
                <div className="flex w-1/2 flex-col ml-5">
                    <span className="text-3xl self-start ml-3 mb-5">Kunde suchen</span>
                    <input
                        type="text"
                        id="input"
                        className="w-5/6        
                            form-control
                            block
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-2
                            focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                        name="search"
                        placeholder="Kunde suchen.."
                        value={inputs.search}
                        onChange={handleChange}
                        autoFocus
                    />
                    <br />
                    <table className="max-w-[90%] mb-10 overflow-x-hidden" id="customers">
                        <tr className="text-center">
                            <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                Name
                            </th>
                        </tr>
                        {search.map((ele) => (
                            <tr className="border-b text-sm max-w-[90%] text-gray-900">
                                <td className="py-4 flex">
                                    <div className="justify-self-start ml-10">
                                        {ele[1][0] == "Firma" ? (
                                            <HiOutlineOfficeBuilding size={23} />
                                        ) : (
                                            <AiOutlineUser size={23} />
                                        )}
                                    </div>
                                    <a
                                        className="justify-self-center ml-12"
                                        href={url + ele[1][1]}
                                    >
                                        {ele[1][1]}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Kunden;

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./login";
import Dashboard from "./dashboard";
import Router from "./router";
import Navbar from "./navbar";
import Kunden from "./kunden";
import Interaktionen from "./interaktionen";
import Gruppen from "./kundengruppe";
import Crmuser from "./crmuser";
import View from "./viewCustomer";
import UserView from "./userView";
import Newsletter from "./newsletter";

function App() {
    return (
        <div className="w-full">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Router />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/CRM" element={<Navbar />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="kunden" element={<Kunden />} />
                        <Route path="interaktionen" element={<Interaktionen />} />
                        <Route path="gruppen" element={<Gruppen />} />
                        <Route path="crmuser" element={<Crmuser />} />
                        <Route path="view" element={<View />} />
                        <Route path="userView" element={<UserView />} />
                        <Route path="newsletter" element={<Newsletter />} />
                        <Route path="*" element={<Dashboard />} />
                    </Route>
                    <Route path="*" element={<Router />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

// <Route index path="otherlist" element={<OtherList />} />

export default App;

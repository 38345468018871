/*

######## ATTENTION ########
This site was written two days before I had to be finished with the project.
There are a few Bugs I have allready found in the error handling but not fixed
I assume that there are many more bugs I haven't found yet in the frontend and
the backend!


*/


import { useEffect, useState } from "react";


/**
 * It takes a cookie name as a parameter, and returns the value of the cookie with that name
 * @param cname - The name of the cookie you want to get.
 * @returns The value of the cookie.
 */
 function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
/**
 * If the cookie exists, return the value of the cookie, otherwise return false
 * @returns The value of the cookie named "key"
 */
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}


function Newsletter() {

    // groups
    const [groups, setGroups]=useState([]);

    // get jwt from cookie
    const localKey = checkCookie();

    // input field values
    const [inputs, setInputs] = useState([]);

    // loading
    const [loadingGroups, setLoadingGroups] = useState(true);

    // error
    const [error, setError]=useState([]);

    // status from post request
    const [status, setStatus]=useState([]);
    const [statusMsg, setStatusMsg]=useState([]);

    // inputs from dynamic inputs
    const [inputList, setInputList] = useState([{ header: "", paragraph: "", image: "" }]);


    // on input field change
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);
        
    };

    // handle change for dynamic inputs
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { header: "", paragraph: "", image: "" }]);
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        var errors = "";
        var gruppe = "";
        var liste = "";

      // DEBUG:  console.log(inputList);


        // check if variables have valid values
        // NOTE: this does not actually work but I don't have time to implement it correctly
        // use it as a template or something like this (inputs.group not found)
        if(inputs.group.trim() && inputs.group !== "-") {
            gruppe = inputs.group.trim();
        } else {
            errors += "Gruppe darf nicht leer sein. ";
        }
         
        if (inputList.length > 0) {
            liste = inputList;
        } else {
            errors += "Es muss mindestens einen Abschnitt für den Newsletter haben. "
        }

        // check if there are any errors
        if(errors !== "") {
            setError(errors);
        } else {
            // make api request to send newsletter

            // header
            // header
            var requestHeader = new Headers();
            requestHeader.append("Content-Type", "text/plain");
            requestHeader.append("Authorization", "Bearer " + localKey);

            // body
            var requestBody = JSON.stringify({
                id: gruppe,
                content: liste
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=sendNewsletter",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setStatusMsg("An Error occured: " + valRes[1]);

                        setStatus(valRes[0]);
                    } else {
                        setStatusMsg(valRes[1]);
                        setStatus(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]);

                        // reset form here
                        // does not work: document.getElementById("customerName").value="";
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });
        }


    };

    // this useeffect only executes once on the first time loading
    useEffect(() => {
        // fetch all groups
        // header
        var requestHeader = new Headers();
        requestHeader.append("Authorization", "Bearer " + localKey);

        // request options
        var requestOptions = {
            method: "GET",
            headers: requestHeader,
            redirect: "follow",
        };

        // fetch Customergroups
        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomerGroups",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // load data from server
                    setGroups(Object.entries(valRes[1]).reverse());
                    // DEBUG: console.log(Object.entries(valRes[1]));

                    // DEBUG:   console.log(valRes[1]);

                    // reset form here

                    setLoadingGroups(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("ERROR: ", error);
            });
    }, []);
// https://github.com/cluemediator/dynamic-input-fields-reactjs/blob/master/src/App.js
    return (
        <div className="flex w-full flex-col text-center ml-[300px]">
            <h1 className="text-6xl mt-10">
                Newsletter
            </h1>
            <div className="flex w-full flex-col items-center">
                <form className="flex flex-col mt-16 align-middle items-center w-5/6"
                    onSubmit={handleSubmit}>
                    <h2 className="text-2xl">Neuer Newsletter an</h2>
                        <select
                            className="form-select text-center block w-1/3 appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                            aria-label="Default select example"
                            id="group"
                            name="group"
                            value={inputs.group}
                            onChange={handleChange}
                        >
                            <option selected value="-">
                                -- Gruppe --
                            </option>
                            {loadingGroups ? (
                                <option value="-">Loading..</option>
                            ) : (
                                groups.map((ele) => (
                                    <option value={ele[0]}>{ele[1][0]}</option>
                                ))
                            )}
                        </select>
                        {
                            inputList.map((x, i) => {
                                return (
                                    <div className="flex flex-col w-full mb-14">
                                        <div className="flex flex-col w-full text-left m-2">
                                            <label for="empfaenger" className="mb-1 font-bold">
                                                Überschrift {i+1}<sup className="text-red-500">*</sup>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control rounded block w-fullrounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                                placeholder="Überschrift 1.."
                                                name="header"
                                                value={x.header}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full text-left m-2 mt-5">
                                            <label for="empfaenger" className="mb-1 font-bold">
                                                Paragraph {i+1}<sup className="text-red-500">*</sup>
                                            </label>
                                            <textarea
                                                className="form-control block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                                placeholder="paragraph"
                                                name="paragraph"
                                                value={x.paragraph}
                                                rows="10"
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full text-left m-2">
                                            <label for="empfaenger" className="mb-1 font-bold">
                                                Bild URL {i+1}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control rounded block w-fullrounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                                placeholder="Überschrift 1.."
                                                name="image"
                                                value={x.image}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </div>
                                        <div className="flex w-full text-left m-2 mt-5">
                                            {inputList.length !== 1 && <button type="button" className="form-control w-1/6 m-2 ml-0 block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out hover:border-[#f78538] focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none" onClick={() => handleRemoveClick(i)}>Abschnitt {i+1} entfernen</button>}
                                            {inputList.length - 1 === i && <button type="button" className="form-control w-1/6 m-2 ml-0 block rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out hover:border-[#f78538] focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none" onClick={handleAddClick}>Neuer Abschnitt</button>}
                                        </div>
                                    </div>
                                );
                            })
                        }

                        { // DEBUG:
                          //  <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
                        }
                        <div
                            id="error"
                            className="flex w-full justify-center mt-3 text-red-500"
                        >
                            {error}
                        </div>
                        <div className="flex w-full justify-center mt-3">
                            <input
                                type="submit"
                                value="Senden"
                                name="submit"
                                class="inline-block m-2 w-1/3 px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                            />
                        </div>
                        <div
                            id="success"
                            className={
                                status == 200
                                    ? "flex w-full justify-center mt-3 mb-10 text-green-500"
                                    : "flex w-full justify-center mt-3 mb-10 text-red-500"
                            }
                        >
                            {statusMsg}
                        </div>
                </form>
            </div>
        </div>
    );
}

export default Newsletter;
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

/**
 * It returns the value of the cookie with the name of cname.
 * @param cname - The name of the cookie you want to get.
 * @returns The value of the cookie with the name of cname
 */
// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

/**
 * If the cookie exists, return the cookie's value, otherwise return false.
 * @returns The value of the cookie.
 */
// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

/**
 * It checks if the user is allready logged in and if the token is still valid. If the token is valid,
 * the user is redirected to the CRM. If the token is invalid, the user is redirected to the login page
 */
function Router() {
    // navigate from react router
    const navigate = useNavigate();

    // check if the user is allready signed in
    // local storage version: localStorage.getItem("key");
    const localKey = checkCookie();

    useEffect(() => {
        if (localKey) {
            // check if token is still valid
            // DEBUG: console.log("User allready logged in once");

            // validtate token from local storage

            // header
            var validateHeader = new Headers();
            // validateHeader.append("Content-Type", "text/plain");
            validateHeader.append("Authorization", "Bearer " + localKey);

            // request options
            var validateRequestOptions = {
                method: "POST",
                headers: validateHeader,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?auth=validate",
                validateRequestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    console.log(valRes);
                    if (valRes[0] === 200) {
                        navigate("/CRM");
                    } else {
                        // token is invalid, clear local storage to remove
                        //localStorage.clear();
                        navigate("/login");
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            //localStorage.clear();
            navigate("/login");
        }
    }, []);
}

export default Router;

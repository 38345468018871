import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// sets the key cookie. Requires cookie name (cname), cookie value (cvalue) and expHours (how many hours the cookie is valid)
function setCookie(cName, cValue, expHours) {
    let date = new Date();
    date.setTime(date.getTime() + expHours * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie =
        cName + "=" + cValue + "; " + expires + ";SameSite=Lax; Secure; path=/";
}

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Login() {
    // navigate from react router
    const navigate = useNavigate();

    // enthält Nachrichten wie errors wenn die Zeit abgelaufen ist
    const [msg, setMsg] = useState([]);

    // error when password or username is wrong
    const [error, setError]=useState([]);

    // check if the user is allready signed in
    // local storage version: localStorage.getItem("key");
    const localKey = checkCookie();

    useEffect(() => {
        if (localKey) {
            // check if token is still valid
            // DEBUG: console.log("User allready logged in once");

            // validtate token from local storage

            // header
            var validateHeader = new Headers();
            validateHeader.append("Content-Type", "text/plain");
            validateHeader.append("Authorization", "Bearer " + localKey);

            // body
            var validateRaw = JSON.stringify({
                "": "",
            });

            // request options
            var validateRequestOptions = {
                method: "POST",
                headers: validateHeader,
                body: validateRaw,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?auth=validate",
                validateRequestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    console.log(valRes);
                    if (valRes[0] === 200) {
                        navigate("/CRM/dashboard");
                    } else {
                        // token is invalid, clear local storage to remove
                        setMsg("Die Session ist abgelaufen! Nochmals anmelden");
                    }
                })
                .catch((error) => console.log("error", error));
        }
    }, []);

    const [inputs, setInputs] = useState({});
    const [status, setStatus] = useState([]);
    const [key, setKey] = useState([]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG:   console.log("New " + event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // DEBUG: console.log(inputs);

        // header
        var headers = new Headers();
        headers.append("Content-Type", "text/plain");

        // body
        var raw = JSON.stringify({
            username: inputs.username,
            password: inputs.password,
        });

        // request options
        var requestOptions = {
            method: "POST",
            headers: headers,
            body: raw,
            redirect: "manual",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?auth=authenticate",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let test = Object.entries(result);
                let res = test.map((ele) => ele[1]);
                if(res[0] !== 200) {
                    setError("Falscher Benutzername oder Passwort!");
                }
                setStatus(res[0]);
                setKey(res[1]);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        // check if string is emtpy
        if (typeof status === "number") {
            // DEBUG: console.log("Status: " + status + ", Key: " + key);

            if (status === 200) {
                //localStorage.setItem("key", key);
                setCookie("key", key, 12);
                navigate("/CRM/dashboard");
            }
        }
    }, [status, key]);

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="w-1/2 bg-white shadow-xl rounded-lg p-5 flex flex-col text-center items-center">
                <img
                    className="max-w-[40%]"
                    src="https://i0.wp.com/blj.zbw.ch/wp-content/uploads/2021/05/cropped-BLJ_Logo-transparent-1.png?resize=1527%2C1080&ssl=1"
                    alt="bljlogo"
                />
                <h2 className="text-4xl">Anmelden</h2>
                <form
                    onSubmit={handleSubmit}
                    className="w-full mt-5 flex flex-col justify-center items-center"
                >
                    <input
                        type="text"
                        className="        form-control
                    block
                    w-[60%]
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    my-3
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Benutzername.."
                        name="username"
                        value={inputs.username}
                        onChange={handleChange}
                        autoFocus
                    />
                    <input
                        type="password"
                        className="        form-control
                    block
                    w-[60%]
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    my-3
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Passwort.."
                        name="password"
                        value={inputs.password}
                        onChange={handleChange}
                    />
                    <p>{msg}</p>
                    <input
                        type="submit"
                        value="Anmelden"
                        className="inline-block mt-5 px-6 py-2.5 bg-[#ff6f31] text-white font-bold text-s leading-tight uppercase rounded shadow-md hover:bg-[#ff6f31] hover:shadow-lg hover:cursor-pointer focus:bg-[#ff6f31] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#ff6f31] active:shadow-lg transition duration-150 ease-in-out"
                    />
                    <p className="mt-3 text-red-500">{error}</p>
                </form>
            </div>
        </div>
    );
}

export default Login;

import React, { useEffect, useState } from "react";

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Crmuser() {
    // crm users
    const [users, setUsers] = useState([]);

    // loading
    const [loadingUsers, setLoadingUsers] = useState(true);

    // get jwt from cookie
    const localKey = checkCookie();

    // errors when new customer is created
    const [error, setError] = useState([]);

    // content from input fields
    const [inputs, setInputs] = useState({});

    // api response for addCRMUser request
    const [insert, setInsert] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    // prepare link for customer view
    var url = window.location.href;
    url = url.replace("crmuser", "userView?id=");

    // when an inputfield value is changed
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    // on first render
    useEffect(() => {
        // fetch customer
        fetchData();
    }, []);

    // add new crm user
    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        setError("");

        // errors variable
        var errors = "";

        // check if values are valid
        var username = "";
        var email = "";
        var password = "";
        var rpassword = ""; // rpassword = repeated password
        var profilBildUrl = "/"; // this is a default value. Profile pictures are not supported yet. They are not important so the chance that they ever will be implemented is rather small.
        var group = 1; // this is a default value. CRM User Groups are not fully implemented and they are not planned for the first version. They are usefull when the CRM has users that don't have the same permission for examples admins

        if (inputs.username) {
            username = inputs.username.trim();
        }
        if (inputs.email) {
            email = inputs.email.trim();
        }
        if (inputs.password) {
            password = inputs.password.trim();
        }
        if (inputs.rpassword) {
            rpassword = inputs.rpassword.trim();
        }

        if (username === "") {
            errors += "Username darf nicht leer sein. ";
        }
        if (email === "") {
            errors += "E-Mail darf nicht leer sein. ";
        }
        if (password === "" || rpassword === "") {
            errors += "Passwort darf nicht leer sein. ";
        } else if (password !== rpassword) {
            errors += "Passwörter müssen übereinstimmen!";
        }

        // check if there are any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to insert the new user

            // header
            var requestHeader = new Headers();
            requestHeader.append("Content-Type", "text/plain");
            requestHeader.append("Authorization", "Bearer " + localKey);

            // body
            var requestBody = JSON.stringify({
                username: username,
                email: email,
                profilBildUrl: profilBildUrl,
                passwort: password,
                rpasswort: rpassword,
                fk_group: group,
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,
                redirect: "manual",
            };

            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=addCRMUser",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setInsert("An Error occured: " + valRes[1]);

                        setInsertStatus(valRes[0]);
                    } else {
                        // load data from server
                        setInsert(valRes[1]);
                        setInsertStatus(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname

                        // reset form here

                        // reload user list
                        fetchData();
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });

                // clear input
                inputs.username="";
                inputs.email="";
                inputs.password="";
                inputs.rpassword="";
        }
    };

    // fetch crm users
    function fetchData() {
        // fetch customers
        //headers
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCRMUsers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // load data from server
                    setUsers(Object.entries(valRes[1]));
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    setLoadingUsers(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });
    }

    return (
        <div className="w-full ml-[300px]">
            <div className="flex w-full flex-col text-center">
                <h1 className="text-6xl mt-10">CRM User</h1>
                <div className="mt-10 flex flex-row w-full ml-5">
                    <div className="flex flex-col justify-center w-1/2">
                        <table className="mb-10 overflow-x-hidden" id="groups">
                            <tr className="text-center">
                                <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                    Name
                                </th>
                                <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                    E-Mail
                                </th>
                            </tr>
                            {users.map((ele) => (
                                <tr className="border-b text-sm text-gray-900">
                                    <td className="py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                        <a href={url + ele[0]}>{ele[1][0]}</a>
                                    </td>
                                    <td className="py-4 whitespace-nowrap overflow-hidden text-ellipsis break-all">
                                        <a href={"mailto:" + ele[1][1]}>{ele[1][1]}</a>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div className="flex flex-col items-center w-1/2 mt-3">
                        <form
                            className="flex w-full flex-col items-center"
                            onSubmit={handleSubmit}
                        >
                            <h2 className="text-3xl">Neuer User</h2>
                            <div className="flex w-3/4 justify-center mt-5">
                                <input
                                    type="text"
                                    className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Benutzername.."
                                    name="username"
                                    id="username"
                                    value={inputs.username}
                                    onChange={handleChange}
                                />
                                <input
                                    type="email"
                                    className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="E-Mail.."
                                    name="email"
                                    id="email"
                                    value={inputs.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex w-3/4 justify-center mt-3">
                                <input
                                    type="password"
                                    className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Passwort.."
                                    name="password"
                                    id="password"
                                    value={inputs.password}
                                    onChange={handleChange}
                                />
                                <input
                                    type="password"
                                    className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    placeholder="Passwort wiederholen.."
                                    name="rpassword"
                                    id="rpassword"
                                    value={inputs.rpassword}
                                    onChange={handleChange}
                                />
                            </div>
                            <div
                                id="error"
                                className="flex w-full justify-center mt-1 text-red-500"
                            >
                                {error}
                            </div>
                            <div className="flex w-3/4 justify-start mt-3">
                                <input
                                    type="submit"
                                    value="Erstellen"
                                    name="submit"
                                    class="inline-block m-2 w-1/3 px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                                />
                            </div>
                            <div
                                id="success"
                                className={
                                    insertStatus == 200
                                        ? "flex w-full justify-center mt-3 text-green-500"
                                        : "flex w-full justify-center mt-3 text-red-500"
                                }
                            >
                                {insert}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Crmuser;

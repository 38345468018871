import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// icon
import { GrSubtractCircle } from "react-icons/gr";

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}

function Gruppen() {
    // input filed values
    const [inputs, setInputs] = useState({});

    // errors when new group is added
    const [error, setError] = useState([]);
    const [addError, setAddError] = useState([]);

    // get jwt from cookie
    const localKey = checkCookie();

    // api response for addCustomerGroup and addCustomerToGroup request
    const [insert, setInsert] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);
    const [insertAdd, setInsertAdd] = useState([]);
    const [insertStatusAdd, setInsertStatusAdd] = useState([]);

    // group data
    const [groups, setGroups] = useState([]);

    // customer data
    const [customers, setCustomers] = useState([]);

    // loading
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [loadingCustomers, setLoadingCustomers] = useState(true);

    // delete
    const [deleteStatus, setDeleteStatus]=useState([]);
    const [deleteMsg, setDeleteMsg]=useState([]);

    // navigate from react router
    const navigate = useNavigate();

    // prepare link for customer view
    var url = window.location.href;
    url = url.replace("gruppen", "view?customer=");

    // on input field change
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // DEBUG: console.log(event.target.value);

        // filter all table rows and attributes for the inputfield value
        var input, filter, table, tr, i;

        input = document.getElementById("input");
        filter = input.value.toUpperCase();
        table = document.getElementById("groups");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            var groupName = tr[i].getElementsByTagName("td")[0];
            var groupBeschreibung = tr[i].getElementsByTagName("td")[1];
            if (groupName && groupBeschreibung) {
                groupName = groupName.textContent || groupName.innerText;
                groupBeschreibung =
                    groupBeschreibung.textContent || groupBeschreibung.innerText;
                if (
                    groupName.toUpperCase().indexOf(filter) > -1 ||
                    groupBeschreibung.toUpperCase().indexOf(filter) > -1
                ) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };

    // on new group form submit
    const handleSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        setError("");

        // errors variable
        var errors = "";

        // check if values are valid

        var groupName = "";
        var groupBeschreibung = "";

        // DEBUG: console.log(inputs);

        // check if variables have values
        if (inputs.name) {
            groupName = inputs.name.trim();
        }
        if (inputs.beschreibung) {
            groupBeschreibung = inputs.beschreibung.trim();
        }

        // check if required inputs are not empty
        if (groupName === "") {
            errors += "Name darf nicht leer sein. ";
        }
        if (groupBeschreibung === "") {
            errors += "Beschreibung darf nicht leer sein. ";
        }

        // check if there where any errors
        if (errors !== "") {
            setError(errors);
        } else {
            // make api request to insert a new Customergroup

            // header
            var requestHeader = new Headers();
            requestHeader.append("Content-Type", "text/plain");
            requestHeader.append("Authorization", "Bearer " + localKey);

            // body
            var requestBody = JSON.stringify({
                name: groupName,
                description: groupBeschreibung,
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,
                redirect: "manual",
            };

            // insert new Customergroup
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=addCustomerGroup",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setInsert("An Error occured: " + valRes[1]);

                        setInsertStatus(valRes[0]);
                    } else {
                        // load data from server
                        setInsert(valRes[1]);
                        setInsertStatus(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]);

                        // reset form here

                        // reload interactions list
                        fetchData();
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("ERROR: ", error);
                });

                // clear input
                inputs.name="";
                inputs.beschreibung="";
        }
    };

    // on new Interaction form submit
    const handleAddSubmit = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        // empty previous errors
        setAddError("");

        // check if values are valid
        var fk_customer = "";
        var fk_group = "";

        if (inputs.gruppe) {
            fk_group = inputs.gruppe.trim();
        }
        if (inputs.kunde) {
            fk_customer = inputs.kunde.trim();
        }

        if (fk_customer === "" || fk_group === "") {
            setAddError("Bitte Kunde und Gruppe auswählen!");
        } else {
            // make api request to insert the customer into a new group

            // header
            var requestHeader = new Headers();
            requestHeader.append("Content-Type", "text/plain");
            requestHeader.append("Authorization", "Bearer " + localKey);

            // body
            var requestBody = JSON.stringify({
                customer: fk_customer,
                group: fk_group,
            });

            // request options
            var requestOptions = {
                method: "POST",
                headers: requestHeader,
                body: requestBody,
                redirect: "manual",
            };

            // insert new Customergroup
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=addCustomerToGroup",
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        setInsertAdd("An Error occured: " + valRes[1]);

                        setInsertStatusAdd(valRes[0]);
                    } else {
                        // load data from server
                        setInsertAdd(valRes[1]);
                        setInsertStatusAdd(valRes[0]);
                        // DEBUG: console.log(Object.entries(valRes[1]));

                        // DEBUG:   console.log(valRes[1]);

                        // reset form here
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("ERROR: ", error);
                });
                // refresh data
                fetchData();
        }
    };


    function removeFromGroup(customerId, groupId) {
        // API Request to remove a customer from a group (remove an entry in customer_group_zwischentabelle)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        };


            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?action=deleteCustomerFromGroup&customerId="+customerId+"&groupId=" + groupId,
                requestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    //DEBUG:  console.log(valRes);
                    if (valRes[0] !== 200) {
                        // DEBUG: console.log("invalid");
                        // error message here
                        setDeleteStatus(valRes[0]);
                        setDeleteMsg(valRes[1]);
                    } else {

                        // DEBUG: console.log(Object.entries(valRes[1]));
                        // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                        
                        // refresh data
                        fetchData();
                    }
                })
                .catch((error) => {
                    // log this action
                    console.log("error", error);
                });

                // refresh data (somehow does not refresh)
                fetchData();
    }

    // execute on first render
    useEffect(() => {
        // fetch groups and their information
        fetchData();

        // fetch customers
        //headers
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localKey);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomers",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                    setCustomers("An Error occured when trying to fetch customers");
                } else {
                    // load data from server
                    setCustomers(Object.entries(valRes[1]).reverse());
                    // DEBUG: console.log(Object.entries(valRes[1]));
                    // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                    setLoadingCustomers(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("error", error);
            });
    }, []);


    const handleDeleteInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        var del = parseInt(document.getElementById("delete").value);
            // check if input is an integer
            if(Number.isInteger(del)) {
                // enable the delete button
                document.getElementById("del").disabled = false;
            } else {
                // disable the delete button
                document.getElementById("del").disabled = true;
            }
            // DEBUG:  console.log(document.getElementById("delete").value);
    }


    const handleDelete = (event) => {
        event.preventDefault();
        if(inputs.delete.trim() !== "") {
            // API Request to delete the group
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localKey);
    
            var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };
    

                fetch(
                    "https://crm.digitalnatives.ch/CRM/API/api.php?action=deleteCustomerGroup&id="+inputs.delete,
                    requestOptions
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        let arrayRes = Object.entries(result);
                        let valRes = arrayRes.map((ele) => ele[1]);
                        //DEBUG:  console.log(valRes);
                        if (valRes[0] !== 200) {
                            // DEBUG: console.log("invalid");
                            // error message here
                        } else {

                            // DEBUG: console.log(Object.entries(valRes[1]));
                            // DEBUG:   console.log(valRes[1]); // + [1]=firma arry + [1] = firmenname
                        }
                        setDeleteStatus(valRes[0]);
                        setDeleteMsg(valRes[1]);
                    })
                    .catch((error) => {
                        // log this action
                        console.log("error", error);
                    });
                    fetchData();
                    inputs.delete="";
        }
    }

    // fetchData() fetches all groups and their information
    function fetchData() {
        // make api request to fetch all Customergroup

        // header
        var requestHeader = new Headers();
        requestHeader.append("Authorization", "Bearer " + localKey);

        // request options
        var requestOptions = {
            method: "GET",
            headers: requestHeader,
            redirect: "follow",
        };

        // fetch Customergroups
        fetch(
            "https://crm.digitalnatives.ch/CRM/API/api.php?action=getAllCustomerGroups",
            requestOptions
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                let arrayRes = Object.entries(result);
                let valRes = arrayRes.map((ele) => ele[1]);
                //DEBUG:  console.log(valRes);
                if (valRes[0] !== 200) {
                    // DEBUG: console.log("invalid");
                } else {
                    // load data from server
                    setGroups(Object.entries(valRes[1]).reverse());
                    // DEBUG: console.log(Object.entries(valRes[1]));

                    // DEBUG:   console.log(valRes[1]);

                    // reset form here

                    setLoadingGroups(false);
                }
            })
            .catch((error) => {
                // log this action
                console.log("ERROR: ", error);
            });
    }

    return (
        <div className="w-full ml-[300px]">
            <div className="flex w-full flex-col text-center">
                <h1 className="text-6xl mt-10">Kundengruppen</h1>
                <div className="mt-10 flex w-full">
                    <div className="flex w-1/2 flex-col items-center text-center">
                        <h2 className="text-3xl">Neue Gruppe</h2>
                        <div className="flex flex-col w-[90%] m-5">
                            <form
                                onSubmit={handleSubmit}
                                className="flex w-full flex-col"
                            >
                                <div className="flex w-full justify-center mt-3">
                                    <input
                                        type="text"
                                        className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                        placeholder="Name"
                                        name="name"
                                        id="name"
                                        value={inputs.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex w-full justify-center mt-2">
                                    <textarea
                                        className="form-control m-2 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                        placeholder="Beschreibung"
                                        name="beschreibung"
                                        id="beschreibung"
                                        value={inputs.beschreibung}
                                        rows="3"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div
                                    id="error"
                                    className="flex w-full justify-center mt-3 text-red-500"
                                >
                                    {error}
                                </div>
                                <div className="flex w-full justify-center mt-3">
                                    <input
                                        type="submit"
                                        value="Erstellen"
                                        name="submit"
                                        class="inline-block m-2 w-full px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                                    />
                                </div>
                                <div
                                    id="success"
                                    className={
                                        insertStatus == 200
                                            ? "flex w-full justify-center mt-3 text-green-500"
                                            : "flex w-full justify-center mt-3 text-red-500"
                                    }
                                >
                                    {insert}
                                </div>
                            </form>
                            <div className="flex flex-col w-full justify-center items-center mt-10">
                                <span className="text-2xl mb-5">Gruppen suchen</span>
                                <p>
                                    Total: {loadingGroups ? "Loading.." : groups.length}
                                </p>
                                <input
                                    type="text"
                                    id="input"
                                    className="w-3/4        
                                        form-control
                                        block
                                        px-3
                                        py-1.5
                                        text-base
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-2
                                        focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                                    name="search"
                                    placeholder="Gruppe suchen.."
                                    value={inputs.search}
                                    onChange={handleChange}
                                />
                                <table
                                    className="mb-10 overflow-x-hidden w-[90%]"
                                    id="groups"
                                >
                                    <tr className="text-center">
                                        <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                            ID
                                        </th>
                                        <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                            Name
                                        </th>
                                        <th className="text-lg font-bold text-gray-900 py-4 text-ellipsis">
                                            Beschreibung
                                        </th>
                                    </tr>
                                    {groups.map((ele) => (
                                        <tr className="border-b text-sm text-gray-900">
                                            <td className="max-w-[50px] py-4 text-center px-3 overflow-hidden text-ellipsis break-all">
                                                {ele[0]}
                                            </td>
                                            <td className="max-w-[150px] py-4 text-center px-3 overflow-hidden text-ellipsis break-all">
                                                {ele[1][0]}
                                            </td>
                                            <td className="max-w-[150px] py-4 text-center px-3 overflow-hidden text-ellipsis">
                                                {ele[1][1]}
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-1/2 flex-col items-center text-center mr-5">
                        <h2 className="text-3xl">Kunde hinzufügen</h2>
                        <form
                            className="flex w-full flex-col items-center m-5"
                            onSubmit={handleAddSubmit}
                        >
                            <div className="mt-3 flex w-full justify-center">
                                <select
                                    className="form-select m-2 text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="gruppe"
                                    name="gruppe"
                                    value={inputs.gruppe}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Gruppe --
                                    </option>
                                    {loadingGroups ? (
                                        <option value="-">Loading..</option>
                                    ) : (
                                        groups.map((ele) => (
                                            <option value={ele[0]}>{ele[1][0]}</option>
                                        ))
                                    )}
                                </select>

                                <select
                                    className="form-select m-2 text-center block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-[#f78538] focus:bg-white focus:text-gray-700 focus:outline-none"
                                    aria-label="Default select example"
                                    id="kunde"
                                    name="kunde"
                                    value={inputs.kunde}
                                    onChange={handleChange}
                                >
                                    <option selected value="-">
                                        -- Kunde --
                                    </option>
                                    {loadingCustomers ? (
                                        <option value="-">Loading..</option>
                                    ) : (
                                        customers.map((ele) => (
                                            // DEBUG: console.log("ID: " + users[0][0] + ", Name: " + users[0][1][0])
                                            <option value={ele[0]}>{ele[1][1]}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div
                                id="error"
                                className="flex w-full justify-center mt-3 text-red-500"
                            >
                                {addError}
                            </div>
                            <div className="flex w-full justify-center mt-3">
                                <input
                                    type="submit"
                                    value="Hinzufügen"
                                    name="submit"
                                    class="inline-block m-2 w-full px-6 py-2.5 bg-[#f78538] text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-[#e4752b] hover:shadow-lg focus:bg-[#e4752b] hover:cursor-pointer focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#f78538] active:shadow-lg transition duration-150 ease-in-out"
                                />
                            </div>
                            <div
                                id="success"
                                className={
                                    insertStatusAdd == 200
                                        ? "flex w-full justify-center mt-3 text-green-500"
                                        : "flex w-full justify-center mt-3 text-red-500"
                                }
                            >
                                {insertAdd}
                            </div>
                        </form>
                        <div className="flex flex-col">
                            <h2 className="text-2xl mt-32">Gruppen</h2>
                            {
                                 groups.map((ele) => (
                                    <ul className="text-left">
                                        <li className="my-2">
                                            <b className="font-bold">{ele[1][0]}</b>
                                            <ul className="ml-6 list-disc">
                                                {ele[1][2] ? (
                                                    Object.entries(ele[1][2]).map((customer)=>(
                                                        <li><a href={url+customer[1][1]} className="underline mr-1">{customer[1][1]}</a><button type="button" className="text-red-500 align-middle" onClick={() => removeFromGroup(customer[0], ele[0])}><GrSubtractCircle size={16} /></button></li>
                                                    ))
                                                ) : (
                                                    <li className="italic text-gray-500">No Customer</li>
                                                )
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                ))
                            }
                            <div
                                id="success"
                                className={
                                    deleteStatus !== 200
                                        ? "flex w-full justify-center mt-3 text-red-500"
                                        : "flex w-full justify-center mt-3 text-green-500" // impossible to reach (navigates before this is displayed)
                                }
                            >
                                {deleteMsg}
                            </div>
                        </div>
                    </div>
                </div>
                <form className="my-36 w-full flex flex-col justify-center items-center" onSubmit={handleDelete}>
                    <h1 className="font-bold text-4xl mb-10 text-red-500">Danger Zone!</h1>
                    <label for="delete" className="font-bold">Eine Gruppe löschen</label>
                    <input
                    type="text"
                    id="delete"
                    className="w-1/4        
                            form-control
                            block
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-2
                            focus:text-gray-700 focus:bg-white focus:border-[#f78538] focus:outline-none"
                    name="delete"
                    placeholder={"Gruppen ID eingeben.."}
                    value={inputs.delete}
                    onChange={handleDeleteInput}
                    />
                    <button disabled id="del" type="submit" class="inline-block w-1/4 px-6 m-2 py-2.5 bg-red-600 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out disabled:bg-red-400 disabled:text-gray-100 focus:border-[#f78538]">Diese Gruppe Löschen</button>
                    <div
                            id="success"
                            className={
                                deleteStatus !== 200
                                    ? "flex w-full justify-center mt-3 text-red-500"
                                    : "flex w-full justify-center mt-3 text-green-500" // impossible to reach (navigates before this is displayed)
                            }
                        >
                    {deleteMsg}
                </div>
            </form>
        </div>
    </div>
    );
}

export default Gruppen;

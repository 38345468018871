import { useEffect, useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";

// icons
import { CgWebsite } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import { BiNews } from "react-icons/bi"

// gets a cooke with the name of cname
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}

function deleteCookie(name, path) {
    if (getCookie(name)) {
        document.cookie =
            name +
            "=" +
            (path ? ";path=" + path : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

// checks if a cookie exists
function checkCookie() {
    let key = getCookie("key");
    if (key !== "") {
        // console.log(key);
        return key;
    } else {
        return false;
    }
}


function Navbar() {
    // navigate from react router
    const navigate = useNavigate();
    // check if the user is allready signed in
    // local storage version: localStorage.getItem("key");
    const localKey = checkCookie();

    // response from the api
    const [status, setStatus] = useState([]);

        // function to logout
    function logout() {
        deleteCookie("key", "/");
        navigate("/login");
    }

    useEffect(() => {
        if (localKey) {
            // check if token is still valid
            // DEBUG: console.log("User allready logged in once");

            // validtate token from local storage

            // header
            var validateHeader = new Headers();
            validateHeader.append("Content-Type", "application/json");
            validateHeader.append("Authorization", "Bearer " + localKey);
            //validateHeader.append("Access-Control-Allow-Origin", "*");
            //validateHeader.append("Access-Control-Allow-Headers", "*");
            //validateHeader.append("Access-Control-Allow-Methods", "*");

            // body
            var validateRaw = JSON.stringify({
                "": "",
            });

            // request options
            var validateRequestOptions = {
                method: "POST",
                headers: validateHeader,
                body: validateRaw,
                redirect: "manual",
            };

            // send auth recuest to api with jwt in auth header
            fetch(
                "https://crm.digitalnatives.ch/CRM/API/api.php?auth=validate",
                validateRequestOptions
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    let arrayRes = Object.entries(result);
                    let valRes = arrayRes.map((ele) => ele[1]);
                    // DEBUG:   console.log(valRes);
                    if (valRes[0] !== 200) {
                        // token is invalid
                        // navigate back to login
                        navigate("/login");
                    } else {
                        // store api response (current server timestamp)
                        setStatus(valRes[1]);

                        // check if location is invalid (/CRM) and redirect
                        var url = window.location.href;
                        if (url.endsWith("/CRM/") || url.endsWith("/CRM")) {
                            navigate("dashboard");
                        }
                    }
                })
                .catch((error) => {
                    // clear local storage
                    // log this action
                    //  localStorage.clear();
                    console.log("error", error);
                });
        } else {
            // user is not logged in redirect to login
            navigate("/login");
        }
    }, []);

    return (
        <div id="dashboard" className="w-screen flex flex-row overflow-x-hidden">
            <div id="sidebar" className="w-[300px] bg-[#dbdbdb] min-h-screen fixed">
                <img
                    id="logo"
                    className="w-[80%]"
                    src="https://i0.wp.com/blj.zbw.ch/wp-content/uploads/2021/05/cropped-BLJ_Logo-transparent-1.png?resize=1527%2C1080&ssl=1"
                    alt="bljLogo"
                />
                <ul id="options" className="ml-8 mt-10">
                    <li
                        className={
                            window.location.href.endsWith("dashboard")
                                ? "my-3 bg-[#f78538] w-5/6 p-2 rounded-lg text-white flex flex-row"
                                : "my-3 p-2 flex flex-row w-5/6 rounded-lg hover:bg-[#f89e62] hover:text-white hover:cursor-pointer transition duration-100 ease-in-out"
                        }
                    >
                        <CgWebsite size={23} />
                        <Link to="/CRM/dashboard" className="pl-3 w-full h-full">
                            Dashboard
                        </Link>
                    </li>
                    <li
                        className={
                            window.location.href.endsWith("kunden")
                                ? "my-3 bg-[#f78538] w-5/6 p-2 rounded-lg text-white flex flex-row"
                                : "my-3 p-2 flex flex-row w-5/6 rounded-lg hover:bg-[#f89e62] hover:text-white hover:cursor-pointer transition duration-100 ease-in-out"
                        }
                    >
                        <FiUsers size={23} />
                        <Link to="/CRM/kunden" className="pl-3 w-full">
                            Kunden
                        </Link>
                    </li>
                    <li
                        className={
                            window.location.href.endsWith("gruppen")
                                ? "my-3 bg-[#f78538] w-5/6 p-2 rounded-lg text-white flex flex-row"
                                : "my-3 p-2 flex flex-row w-5/6 rounded-lg hover:bg-[#f89e62] hover:text-white hover:cursor-pointer transition duration-100 ease-in-out"
                        }
                    >
                        <GrGroup size={23} />
                        <Link to="/CRM/gruppen" className="pl-3 w-full">
                            Gruppen
                        </Link>
                    </li>
                    <li
                        className={
                            window.location.href.endsWith("newsletter")
                                ? "my-3 bg-[#f78538] w-5/6 p-2 rounded-lg text-white flex flex-row"
                                : "my-3 p-2 flex flex-row w-5/6 rounded-lg hover:bg-[#f89e62] hover:text-white hover:cursor-pointer transition duration-100 ease-in-out"
                        }
                    >
                        <BiNews size={23} />
                        <Link to="/CRM/newsletter" className="pl-3 w-full">
                            Newsletter
                        </Link>
                    </li>
                    <li
                        className={
                            window.location.href.endsWith("crmuser")
                                ? "my-3 bg-[#f78538] w-5/6 p-2 rounded-lg text-white flex flex-row"
                                : "my-3 p-2 flex flex-row w-5/6 rounded-lg hover:bg-[#f89e62] hover:text-white hover:cursor-pointer transition duration-100 ease-in-out"
                        }
                    >
                        <RiAdminLine size={23} />
                        <Link to="/CRM/crmuser" className="pl-3 w-full">
                            User
                        </Link>
                    </li>
                    <li>
                        <button
                            onClick={logout}
                            className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-s leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                        >
                            logout
                        </button>
                    </li>
                </ul>
            </div>
            <Outlet />
        </div>
    );
}

export default Navbar;
